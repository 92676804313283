@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Cabinet Grotesk', sans-serif;
  scroll-behavior: smooth;

}

body{
  background-color: #1d3645;
}


.home-page * {
  will-change: transform, opacity !important;
}


/* 
.button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #177771;
  border-radius: 10rem;
  z-index: -2;
}

.button:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #125f5a;

  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.button:hover {
  color: #fff;
}

.button:hover:before {
  width: 100%;
} */


.containerbg {
  background-color: #1d3645;
  background-image:
    radial-gradient(at left, rgb(22, 40, 51) 0px, transparent 50%),
    radial-gradient(at right, rgb(23, 42, 54) 0px, transparent 50%),
    radial-gradient(at top, #185351 0px, transparent 50%),
    radial-gradient(at bottom, #1f6e6c 0px, transparent 50%);

}


.ChooseUsCard {
  flex-shrink: 0;
  border-radius: 15px;
  border: 0.737px solid rgba(216, 216, 216, 0.00);
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, #237774 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(30.94575309753418px);
  cursor: pointer;
  transition: 0.2;
}


.cardbg {
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, #ffffff66 0%, rgba(255, 255, 255, 0.00) 100%);
}

.btncardbg {
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, #ffffff66 0%, rgba(255, 255, 255, 0.00) 100%);
}
.btncardbg:hover{
  background: transparent;
  color: #22c0c5;
}


.textColor {
  color: #eceaea;
}

.buttonTextColor {
  color: #ffffff;
}


/* -------for hero image rotate----------- */
.rotateimg1 {
  animation-name: rotate;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rotateimg2 {
  animation-name: rotate2;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rotateimg1:hover {
  animation-name: none;
}

.rotateimg2:hover {
  animation-name: none;
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

/* ------------------------------------------------------------- */

.whyChooseUs {
  animation-name: whyChooseUs;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes whyChooseUs {
  from {
    filter: grayscale();
  }

  to {
    filter: none;
  }
}

.infineUm-image-animation {
  animation-name: infineUm;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes infineUm {
  from {
    filter: grayscale();
  }

  to {
    filter: none;
  }
}


.shadow-top {
  box-shadow: 0 -30px 50px -5px #227774;
}





/* ----------------------------Careers----------------------- */
/* styles.css */
@keyframes scaleUp {
  from {
    transform: scale(0.6);
  }

  to {
    transform: scale(1);
  }
}


.cardNew.in-view {
  animation: scaleUp 0.5s forwards;
  transform: scale(1);
}

.card1:before {
  background-color: #18726d;
  border-top-right-radius: 30px;
  content: "";
  height: 120px;
  position: absolute;
  right: -4px;
  top: -4px;
  width: 120px;
}

.card1:after {
  border-bottom-left-radius: 30px;
  bottom: -4px;
}

.card1:after,
.card2:before {
  background-color: #23cec5;
  content: "";
  height: 120px;
  left: -4px;
  position: absolute;
  width: 120px;
}

.card2:before {
  border-top-left-radius: 30px;
  top: -4px;
}

.card2:after {
  border-bottom-right-radius: 30px;
  bottom: -4px;
  right: -4px;
}

.card2:after,
.card3:before {
  background-color: #14726d;
  content: "";
  height: 120px;
  position: absolute;
  width: 120px;
}

.card3:before {
  border-top-left-radius: 30px;
  left: -4px;
  top: -4px;
}

.card3:after {
  border-bottom-right-radius: 30px;
  bottom: -4px;
}

.card3:after,
.card4:before {
  background-color: #23cec5;
  content: "";
  height: 120px;
  position: absolute;
  right: -4px;
  width: 120px;
}

.card4:before {
  border-top-right-radius: 30px;
  top: -4px;
}

.card4:after {
  border-bottom-left-radius: 30px;
  bottom: -4px;
  left: -4px;
}

.card4:after,
.card5:before {
  background-color: #34b4ae;
  content: "";
  height: 120px;
  position: absolute;
  width: 120px;
}

.card5:before {
  bottom: 60px;
  right: -4px;
}

.card5:after {
  left: -4px;
  top: 60px;
}

.card5:after,
.card6:before {
  background-color: #b3dad8;
  content: "";
  height: 120px;
  position: absolute;
  width: 120px;
}

.card6:before {
  bottom: 60px;
  right: -4px;
}

.card6:after {
  background-color: #34b4ae;
  content: "";
  height: 120px;
  left: -4px;
  position: absolute;
  top: 60px;
  width: 120px;
}


.core-value,
.join-us {
  height: 260px
}


.blur-box {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: #fff3;
}


@keyframes slideIn {
  from {
    width: 0%;
    height: 0%;
  }

  to {
    width: 50%;
    height: 60%;
  }
}


.modal-box {
  background: linear-gradient(145deg, #561e1e 11.97%, #22c0c5 89.93%);
  border-radius: 20px 20px 20px 0;
  box-shadow: 10px 2px 30px #2727274d;
  /* animation: slideIn 5s ease-out; */
  width: 90%;
  height: 60%;
}


@keyframes model-content {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.model-content {
  animation: model-content 2s;
}


@keyframes slideMin1024 {
  0% {
    width: 0%;
    height: 0%;
    opacity: 0;
  }

  50% {
    width: 450px;
    height: 50%;
  }

  100% {
    width: 450px;
    height: 50%;
  }
}

@media (min-width: 1024px) {
  .modal-box {
    bottom: 30px;
    left: 30px;
    animation: slideMin1024 1s ease-out;
    width: 450px;
    height: 50%;

  }
}


@keyframes closerev {
  0% {
    width: 450px;
    height: 50%;
    opacity: 1;
  }

  50% {
    width: 250px;
    height: 20%;
    opacity: 0.1;
  }

  100% {
    width: 1%;
    height: 1%;
    opacity: 0;
  }
}

.close {
  animation: closerev 1s ease-out forwards;
}





/* ----------------------tab----------- */

@keyframes slideInmobileTab1024down {
  0% {
    width: 0%;
    height: 0%;
    opacity: 0;
  }

  50% {
    width: 50%;
    height: 50%;
  }

  100% {
    width: 70%;
    height: 50%;
  }
}


@media (max-width: 1043px) {
  .modal-box {
    border-radius: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    animation: slideInmobileTab1024down 1s ease-out;

    width: 70%;
    height: 50%;
  }
}



/* -------------------small device----------- */
@keyframes slideInmobile {
  0% {
    width: 0%;
    height: 0%;
    opacity: 0;
  }

  50% {
    width: 50%;
    height: 50%;
  }

  100% {
    width: 95%;
    height: 390px;
  }
}

@media (max-width: 640px) {
  .modal-box {
    width: 95%;
    height: 400px;
    animation: slideInmobile 1s ease-out;

  }
}





/* ------------------testBall-------------------- */

.ball {
  width: 20px;
  height: 20px;
  background-color: rgb(40, 50, 59);
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  animation: float 3s infinite ease-in-out;
}

.ball:nth-child(odd) {
  background-color: rgb(255, 160, 54);
}

/* Adjust the individual animation delays for each ball */
.ball:nth-child(1) {
  left: 131.619px;
  animation-delay: 1s;
}

.ball:nth-child(2) {
  left: 183.072px;
  animation-delay: 1.5s;
}

/* Add similar styling and animation-delay adjustments for other balls */

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-60px);
  }

  100% {
    transform: translateY(0);
  }
}













/* ----------------------------------footer wev---------------------------------------- */
.box {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}


.box-inner {
  position: relative;
  width: 100%;
  height: 100%;

}


.box:before,
.box-inner {
  background-image:
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%231d3645' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%231d3645' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='b' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%23185351' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%23444477' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23b)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='c' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%23185351' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%23344e5a' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23c)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e");
  background-repeat: repeat-x;
  background-size: 1600px 50%;
  background-position: 0 130%, -50px 130%, 500px 130%;

  animation: 20s waves linear infinite forwards;
}



