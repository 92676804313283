
.clear-fix:after {
  display: block;
  clear: both;
  content: '';
}

.img-responsive {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__collection {
  position: relative;
  display: block;
  padding: 10px;
  overflow: hidden;
}

.card__collection .cards {
  width: 300px;
  height: 400px;
  display: block;
  background-size: cover;
  float: left;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  background-position: center;
  margin: 10px;
}





.card__collection .cards--two:hover p {
  left: 6%;
}


.card__collection .cards--two:hover .cards--two__rect {
  top: 75%;
}

.card__collection .cards--two:hover .cards--two__rect::before {
  transform: translateY(15px);
}

.card__collection .cards--two:hover li {
  transform: translateY(0);
}

.card__collection .cards--two:hover .cards--two__tri {
  right: -40%;
}

.card__collection .cards--two:hover .cards--two__tri::before {
  right: -312px;
}

.card__collection .cards--two p {
  position: absolute;
  top: 83%;
  left: -100%;
  text-transform: capitalize;
  color: #fff;
  font-size: 20px;
  z-index: 8;
  transition: all 0.6s ease;
}

.card__collection .cards--two img {
  transition: all 0.2s ease;
}


.card__collection .cards--two__tri::before {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #1d3645;
  position: absolute;
  content: '';
  display: block;
  top: -220px;
  right: -612px;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.card__collection .cards--two__rect {
  width: 750px;
  height: 200px;
  background: #1d3645;
  display: block;
  position: absolute;
  top: 175%;
  left: -78%;
  transform: rotate(30deg);
  z-index: 5;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}

.card__collection .cards--two__rect::before {
  content: '';
  display: block;
  width: 100%;
  position: relative;
  height: 100%;
  background: #1f6e6c;
  transform: translateY(200px);
  z-index: 2;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.card__collection .cards--two ul {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 10px;
  z-index: 9;
}

.card__collection .cards--two ul li {
  display: inline-block;
  font-size: 16px;
  margin: 7px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  transform: translateY(100px);
}

.card__collection .cards--two ul li:nth-child(2) {
  transition-delay: 0.2s;
}

.card__collection .cards--two ul li:nth-child(3) {
  transition-delay: 0.3s;
}

.card__collection .cards--two ul li:nth-child(4) {
  transition-delay: 0.4s;
}




/* ---------------------------------Services page timeline------------------------------------------- */


.main-timeline:after {
  content: '';
  display: block;
  clear: both;
}

.main-timeline .timeline {
  width: 50%;
  padding: 15px;
  margin: 0 2px 0 0;
  float: left;
  position: relative;
  z-index: 1;
}

.main-timeline .timeline-content {
  text-align: center;
  padding: 90px 20px 20px;
  border: 10px solid var(--main-color);
  border-top-color: transparent;
  border-left-color: transparent;
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.7);
  display: block;
  position: relative;


  background-color: #1d3645;
}

.main-timeline .timeline-content:hover {
  text-decoration: none;
}

.main-timeline .timeline-content:before {
  content: '';
  background: var(--main-color);
  width: 150px;
  position: absolute;
  right: -25px;
  top: -25px;
  bottom: -25px;
  z-index: -2;
}

.main-timeline .timeline-icon {
  color: var(--main-color);
  background-color: #129e97;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  line-height: 60px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5), 0 0 0 10px var(--main-color);
  position: absolute;
  left: 5px;
  top: 5px;
}

.main-timeline .title {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 8px;
}

.main-timeline .description {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 0;
}

.main-timeline .timeline:nth-child(even) {
  margin: 0 0 0 2px;
  float: right;
}

.main-timeline .timeline:nth-child(even) .timeline-content:before {
  right: auto;
  left: -25px;
}

.main-timeline .timeline:nth-child(even) .timeline-icon {
  left: auto;
  right: 5px;
}

.main-timeline .timeline:nth-child(even) .timeline-content {
  border-color: var(--main-color);
  border-right-color: transparent;
  border-top-color: transparent;
}

.main-timeline .timeline:nth-child(4n+2) {
  --main-color: #185351;
}

.main-timeline .timeline:nth-child(4n+3) {
  --main-color: #1f6e6c;
}

.main-timeline .timeline:nth-child(4n+4) {
  --main-color: #185351;
}

@media screen and (max-width:767px) {

  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    width: 100%;
    margin: 10px;
  }


  .main-timeline .timeline-content {

    padding: 90px 0px 20px;

  }
}




/* ----------------------------------------blog------------------------------------------------- */


/*-----------------
	38. Blog
-----------------------*/

.blog {
	border: 1px solid #f0f0f0;
	background-color: transparent;
	border-radius: 10px;
	margin-bottom: 30px;
	padding: 1.5rem;
	position: relative;
}
.blog-image {
	overflow: hidden;
}
.blog-image,
.blog-image > a,
.blog-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}
.blog-image img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-image a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-image {
	margin-bottom: 30px;
}
.blog-content {
	position: relative;
}
.blog-title {
	font-size: 22px;
	margin: 0 0 10px;
}
.blog-title a {
	color: #ffffff;
}
.blog-title a:hover {
	color: #20c0f3;
}
.blog-content p {
	color: #ffffff;
	font-size: 14px;
	margin: 0 0 30px;
}
.read-more {
    display: inline-block;
    font-size: 16px;
    padding: 0;
    color: #20c0f3;
    text-decoration: underline;
    font-weight: bold;
}
.blog-info {
    color: #2dd4bf;
    font-size: 14px;
    margin-bottom: 15px;
}
.blog-info a {
	color: #2dd4bf;
}
.blog-info a:hover {
	color: #2dd4bf;
}
.blog-info i {
	color: #20c0f3;
	font-size: 14px;
	margin-right: 5px;
}
.entry-meta {
	color: #757575;
	font-size: 14px;
	list-style: none;
	margin-bottom: 15px;
	padding: 0;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.entry-meta li:last-child {
	margin-right: 0;
}
.blog-pagination .pagination {
	margin-bottom: 30px;
}
.blog-pagination .pagination li a {
    padding: 12px 20px;
    font-weight: 600;
    color: #2E3842;
    margin-right: 10px;
    border-radius: inherit !important;
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.disabled a {
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.active a {
	background: #20c0f3;
	border-color: transparent;
	color: #ffffff;
}
.post-left ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.post-left ul li {
	margin-bottom: 5px;
	margin-right: 20px;
}
.post-left ul li:last-child {
	margin-right: 0;
}
.post-author a {
	display: flex;
	align-items: center;
}
.video {
	position: relative;
	padding-bottom: 56%;
	height: 0;
	overflow: hidden;
}
.video iframe,
.video object,
.video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.latest-posts li {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.latest-posts li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}
.post-thumb {
	border-radius: 4px;
	width: 80px;
	float: left;
	overflow: hidden;
}
.post-thumb a img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.post-thumb a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.post-info {
	margin-left: 95px;
}
.post-info h4 {
	font-size: 15px;
	margin: 0 0 5px;
}
.post-info h4{
	color: white;
}
.post-info h4 a:hover {
	color: teal;
  font-weight: bold;
}
.post-info p {
	color: #d1d0d0;
	font-size: 12px;
	margin: 0;
}
.post-info p i {
	color: #0071DC;
	font-size: 14px;
	margin-right: 4px;
}
.category-widget .categories {
	list-style: none;
	margin: 0;
	padding: 0;
}
.category-widget .categories li {
    margin-bottom: 20px;
}
.category-widget .categories li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.category-widget .categories li {
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
}
.category-widget .categories li a span {
    float: right;
    color: #ffffff;
}
.category-widget .categories li a:hover {
	color: #20c0f3;
}
.category-widget .categories > li > a > i {
	color: #0071DC;
	font-size: 18px;
	margin-right: 10px;
}
.tags-widget .card-body {
	padding-bottom: 1rem;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}
.tags li {
	float: left;
}
.tag {
	border: 1px solid white;
	border-radius: 4px;
	color: white;
	padding: 5px 10px;
	position: relative;
	margin: 0 .5rem .5rem 0;
	font-size: 14px;
	border: 1px solid #ccc;
	display: inline-block;
}
.tag:hover {
	background-color: #20c0f3;
	border-color: #20c0f3;
	color: #fff;
}
.comment-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.comments-list .comment-block p {
    font-size: 14px;
    margin-bottom: 5px;
}
.blogs-section {
	padding: 50px 0;
	background-color: #ffffff;
}
.blogs-section.blog-container {
	padding: 60px 0;
}
.blog-wrapper {
	background: #FFFFFF;
	border: 1px solid #E8EEFF;
	box-sizing: border-box;
	border-radius: 10px 10px 0px 0px;
	margin-bottom: 30px;
	position: relative;
}
.blog-wrapper .wrap-image img {
	width: 100%;
	border-radius: 10px 10px 0px 0px;
}
.blog-wrapper .wrap-content {
	padding: 10px;
}
.blog-wrapper .wrap-content:before {
	width: 0;
    height: 0;
    border-left: 149px solid transparent;
    border-right: 149px solid transparent;
    border-bottom: 20px solid #ffffff;
    margin-left: 0;
    position: absolute;
    margin-top: -30px;
    left: 15px;
}
.blog-wrapper .wrap-content .sp-title {
	font-size: 13px;
	color: #2264C5;
	font-weight: 600;
	margin-right: 5px;
}
.blog-wrapper .wrap-content .post-author img {
	width: 20px;
  height: 20px;
  flex: 0 0 20px;
	margin: 0 5px;
}
.blog-wrapper .wrap-content .post-author span {
	font-size: 12px;
	font-weight: 600;
	color: #000000;
}
.blog-wrapper .wrap-content .date-cart {
	background-color: #1860CA;
    padding: 10px 8px;
    font-size: 28px;
    color: #fff;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    width: 65px;
    height: 60px;
    margin: 0;
    position: absolute;
    right: 25px;
    margin-top: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.blog-wrapper .wrap-content .date-cart span {
	font-size: 11px;
    font-weight: 500;
}
.blog-wrapper .wrap-content-body {
	margin-top: 10px;
}
.blog-wrapper .wrap-content-body h3 a {
	font-size: 18px;
	font-weight: 500;
	color: #000;
	line-height: 1.5;
}
.blog-wrapper .wrap-content-body p {
	font-size: 12px;
	color: #000;
}
.blog-wrapper .wrap-content-body a.read-txt {
	width: 110px;
	padding: 10px;
	background: #0CE0FF;
	border-radius: 30px;
	display: block;
	text-align: center;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
}
.blogs-section .view-btn {
    background-color: #0071DC;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    border-radius: 30px;
    border: 1px solid #0071DC;
    padding: 6px 20px;
    box-shadow: inherit;
}
.blogs-section .view-btn {
	box-shadow: inherit;
}
.blogs-section .view-btn-light {
	background-color: #20C0F3;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    border-radius: 30px;
    border: 1px solid #20c0f3;
    padding: 6px 20px;
}
.blog-container .blog-wrapper .wrap-content .date-cart {
	top: 50px;
    right: 25px;
}
.blog-container .blog-wrapper .wrap-content-body h3 a {
	color: #1860CA;
}
.blog-container .blog-wrapper .wrap-content .sp-title {
	color: #28DAF4;	
    font-size: 14px;
}

/*-----------------
	39. Blog Details
-----------------------*/

.blog-view .blog-title {
	font-size: 24px;
}
.blog-content p:last-child {
	margin-bottom: 0;
}
.blog-view .blog-info {
	border: 0;
	margin-bottom: 20px;
	padding: 0;
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
}
.social-share > li {
	display: inline-block;
	float: left;
	margin-left: 10px;
	text-align: center;

}
.social-share > li:first-child {
	margin-left: 0;
}
.social-share > li {
	border: 1px solid #dfdfdf;
	color: #ffffff;
	display: inline-block;
	font-size: 22px;
	height: 40px;
	line-height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	
}
.social-share > li:hover {
	background-color: #20c0f3;
	color: #fff;
	border-color: #20c0f3;
}
.blog-reply > a {
	color: #999;
	font-size: 12px;
	font-weight: 500;
}
.blog-date {
	color: #d6d6d6;
	font-size: 12px;
}
.blog-comments .comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.blog-comments .comments-list li {
	clear: both;
	padding-left: 80px;
}
.blog-comments .comments-list li .comment {
	margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
	left: 0;
	position: absolute;
}
.blog-comments .comments-list li img.avatar {
	height: 58px;
	width: 58px;
	border-radius: 58px;
}
.blog-comments .comment-btn {
    color: #20c0f3;
    display: flex;
	align-items: center;
	gap: 4px;
    font-weight: 500;
    font-size: 15px;
}
.blog-author-name {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}
.new-comment label {
	font-weight: 500;
}
.comment-submit .btn {
	background-color: #0071DC;
	border-color: #0071DC;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 26px;
	color: #fff;
}
.about-author-img {
	height: 100px;
	width: 100px;
	overflow: hidden;
	position: absolute;
}
.author-details {
	margin-left: 120px;
}
.about-author {
	min-height: 100px;
}
.author-details .blog-author-name {
	display: inline-block;
	margin-bottom: 10px;
}
.post-author img {
    border-radius: 100%;
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    margin-right: 5px;
}
.post-author a > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.custom-edit-service textarea.form-control{
	min-height: 200px;
}
.custom-edit-service .service-upload {
    border: 1px solid #dcdcdc;
    border-radius: .25rem;
    text-align: center;
    padding: 70px 0;
    margin-bottom: 30px;
    background-color: #fff;
    position: relative;
}
.custom-edit-service .service-upload input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.custom-edit-service .service-upload i{
	font-size: 50px;
    color: #858585;
}
.custom-edit-service .service-upload span {
    font-size: 15px;
    color: #858585;
    margin-top: 14px;
    display: block;
}
.custom-edit-service .upload-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}
.custom-edit-service .upload-wrap li {
    margin: 10px;
}
.custom-edit-service .upload-wrap li:first-child{
	margin-left: 0;
}
.custom-edit-service .upload-wrap li img{
	max-width: 100%;
}

/*-----------------
	40. Blog Grid
-----------------------*/

.grid-blog .blog-title {
    font-size: 18px;
    margin: 0 0 5px;
    min-height: 43px;
}
.grid-blog .blog-content p {
	margin: 0 0 15px;
}
.grid-blog .blog-info {
	margin-bottom: 15px;
}
.grid-blog .blog-image {
	margin-bottom: 20px;
}
.blog.grid-blog {
	padding: 20px;
}
.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0;
}
.grid-blog .entry-meta li {
	margin-bottom: 10px;
}
.grid-blog .entry-meta li:last-child {
	flex: 0 0 100px;
	max-width: 100px;
}
.grid-blog .post-author {
    width: 189px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.grid-blog .post-author a:hover {
	color: #20c0f3
}


.date h6{
	font-size: 10px;
	margin-bottom: 0px;
	color: #000;
}

/*-----------------
	87. Blog Section Five
-----------------------*/




/*-----------------
	112. Responsive
-----------------------*/
@media (max-width: 1024px) {
	.grid article {
	  width: 31.3%;
	}
  }

@media only screen and (min-width:768px) {

	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
}
@media (min-width: 992px) {
	.main-nav > li {
		margin-right: 20px;
	}
	.white-font > li > a {
		color: #fff;
	}
	.main-nav > li:last-child {
		margin-right: 0;
	}
	.main-nav li {
		display: block;
		position: relative;
	}
	.main-nav > li > a {
		line-height: 85px;
		padding: 0 !important;
	}
	.header-nav-one .main-nav > li > a {
		line-height: 97px;
	}
	.header-four .main-nav > li > a {
		line-height: 80px;
		color: #fff;
	}
	.main-nav > li > a > i {
		font-size: 12px;
		margin-left: 3px;
	}
	
	.main-nav li > ul {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 3px  rgba(0, 0, 0, 0.1);
		display: block;
		font-size: 14px;
		left: 0;
		margin: 0;
		min-width: 200px;
		opacity: 0;
		padding: 0;
		position: absolute;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
		top: 100%;
		visibility: hidden;
		z-index: 1000;
	}
	.main-nav li .submenu::before {
		border: 7px solid #fff;
		border-color: transparent transparent #fff #fff;
		box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
		content: "";
		left: 45px;
		position: absolute;
		top: 2px;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
	}
	.main-nav li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.main-nav .has-submenu.active > a {
		color: #10DEFD;
	}
	.header-four .main-nav .has-submenu.active > a {
		color: #fff;
    	border-top: 3px solid #E74E84;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #09dca4;
	}
	.nav-bg-two {
		font-family: 'Encode Sans', sans-serif;
	}
	.nav-bg-two .has-submenu.active > a {
		color: #0071DC;
	}
	.nav-bg-two .has-submenu.active .submenu li.active > a {
		color: #0071DC;
	}
	.nav-bg-five .main-nav .has-submenu.active .submenu li.active > a {
		color: #0071DC;
	}
	.nav-bg-five .main-nav li .submenu a:hover {
	    color: #0071DC;
	}
	.nav-bg-five .black-font {
		font-family: 'Inter', sans-serif;
	}
	.nav-bg-fifteen .main-nav .has-submenu.active .submenu li.active > a {
		color: #0071DC;
	}
	.nav-bg-fifteen .main-nav li .submenu a:hover {
	    color: #0071DC;
	}
	.nav-bg-fifteen .black-font {
		font-family: 'Inter', sans-serif;
	}
	.main-nav > li .submenu li:first-child a {
		border-top: 0;
	}
	.main-nav > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: -1px;
		margin-right: 0;
	}
	.main-nav > li .submenu > li .submenu {
		left: 100%;
		top: 0;
		margin-top: 10px;
	}
	.main-nav li .submenu a:hover {
		color: #09dca4;
		letter-spacing: 0.5px;
		padding-left: 20px;
	}
	.main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
		top: 20px;
		margin-left: -35px;
		box-shadow: 1px 1px 0 0 rgba(0,0,0,.15);
		border-color: transparent #fff #fff transparent;
	}
	.header-navbar-rht .show + .dropdown-menu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.header-navbar-rht li .dropdown-menu {
		border-radius: 5px;
		padding: 0;
		margin: 0;
		min-width: 200px;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		display: block;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
	}
	.header-trans-two .main-nav .has-submenu.active > a {
		color: #0071DC;
	}
	.header-trans-two .main-nav .has-submenu.active > a:after {
		content: "";
		position: absolute;
		width: 70px;
		background: #0071DC;
		left: -7px;
		height: 3px;
		z-index: 9;
		bottom: 0px;
	}
	.header-five .main-nav .has-submenu.active > a {
		color: #28DAF4;
	}
	.header-five .main-nav .has-submenu.active > a:after {
		content: "";
		position: absolute;
		width: 70px;
		background: #28DAF4;
		left: -7px;
		height: 2px;
		z-index: 9;
		bottom: 25px;
	}
}

@media only screen and (max-width: 1399px) {
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 180px;
	}
	.row.row-grid {
		margin-left: -8px;
		margin-right: -8px;
	}
	.row.row-grid > div {
		padding-right: 8px;
		padding-left: 8px;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.pharm-slide-home .slide-image h2 {
		font-size: 100px;
	}
	.pharm-slide-home .slide-image h2 span {
		font-size: 60px;
	}
	.main-nav > li {
		margin-right: 20px;
	}
}
@media (min-width: 1200px) { 
	.aval-features .custom-contain {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
}
@media (min-width: 1600px) { 		
	.blog-wrapper .wrap-content:before {
		border-left: 221px solid transparent;
		border-right: 221px solid transparent;
	}
}
@media only screen and (max-width: 1200px) {
	.home-one-banner {
		padding: 0;
	}
	.nav-bg {
		background: transparent;
	}
	.nav-bg .main-menu-wrapper {
	    display: unset;
	}
	.nav-bg .navbar-header {
	    margin-top: 0px;
	}
	.nav-bg .header-nav {
		height: auto;
	}
	.news-bg .news-left .form-control {
		width: 290px;
	}
	.header-trans.header-trans-two .main-menu-wrapper {
		margin-left: auto;
	}
	.header-trans.header-trans-two .logo {
		width: auto;
	}
	.best-doctor-slider .slick-prev, .best-doctor-slider .slick-next {
	    width: 40px;
	    height: 40px;
	}
	.best-doctor-slider .slick-next {
	    right: -10px;
	    left: auto;
	}
	.best-doctor-slider .slick-prev {
	    left: -10px;
	    right: auto;
	}
	.home-banner-three .doc-form {
		padding: 0;
	}
	.home-banner-three .banner-three-content h2 {
		font-size: 34px;
	}
	.home-banner-three .banner-wrapper {
	    max-width: 450px;
	}
	.pharm-slide-home {
		border-radius: 0px 0px 200px 0px;
	}
	.offer-img {
		max-width: 270px;
	}
	.offer-widget {
		padding: 40px;
	}
	.offer-widget a {
		font-size: 16px;
		padding: 11px 20px;
	}
	.pharm-grid-blog .btn {
		font-size: 16px;
	}
}

@media only screen and (max-width: 1199.98px) {
	.header-nav {
		padding-left: 20px;
		padding-right: 20px;
	}
	.container-fluid {
		padding-left: 20px;
		padding-right: 20px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 150px;
	}
	.chat-cont-left {
		-ms-flex: 0 0 40%;
		flex: 0 0 40%;
		max-width: 40%;
	}
	.chat-cont-right {
		-ms-flex: 0 0 60%;
		flex: 0 0 60%;
		max-width: 60%;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.dash-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.circle-bar {
		margin: 0 0 15px;
	}
	.header-navbar-rht li.contact-item {
		display: none;
	}
	.map-right {
		height: 450px;
		position: static;
		min-height: inherit;
		margin-bottom: 30px;
		padding-right: 15px;
	}
	.map-listing {
		height: 450px;
	}
	.map-page .content {
		padding-top: 100px;
	}
	.appoinment-box {
		margin-top: 0;
	}
	.appoinment-box .appoinment-search-btn {
		font-size: 14px;
		min-width: 140px;
	}
	.appoinment-box .appoinment-btn {
		margin-right: 0px;
    	width: 48px;
	}
	.appointment-grid {
		margin-top: 0;
	}
	.doctor-right-img {
		display: none;
	}
	.pharm-slide-home h6 {
		font-size: 36px;
	}
	.pharm-slide-home .slide-image h2 {
		font-size: 70px;
	}
	.pharm-slide-home p {
		font-size: 18px;
	}
	.pharm-slide-home .slide-image {
		padding: 90px 0;
	}
	.help-box span {
		width: 70px;
		height: 70px;
		margin-right: 10px;
	}
	.help-info p, .pro-list .deal-pro-detail p,
	.filter-new .filter-widget ul li a, .filter-new .filter-widget ul li {
		font-size: 14px;
	}
	.help-info h5 {
		font-size: 16px;
	}
	.category-tab ul li a {
		font-size: 18px;
		padding: 12px 25px;
	}
	.que-wrap h2 {
		font-size: 38px;
	}
	.pharm-grid-blog .blog-content h3 {
		font-size: 20px;
	}
	.pharm-viewicon .view-icons a {
		margin: 0 10px 0 0;
	}
	.pharm-viewicon p {
		font-size: 16px;
	}
	.pro-list .deal-pro-detail h4 {
		font-size: 22px;
	}
	.pro-pricelist ul li, .pro-pricelist h2 {
		margin: 0 10px 5px 0;
	}
	.pro-pricelist ul li a {
		width: 40px;
		height: 40px;
	}
	.pro-pricelist .book-btn {
		font-size: 15px;
		padding: 7px 15px;
		margin: 0 10px 5px 0;
	}
	.product-det-buy .product-quantity {
		height: 44px;
		min-width: 150px;
	}
	.product-quantity .quntity-input {
		font-size: 20px;
	}
	.product-quantity .quantity-btn {
		font-size: 28px;
	}
	.filter-new {
		padding: 20px;
	}
	.order-info .nav li {
		padding-right: 60px;
	}
	.pharmacy-booking .view-details-btn {
		margin-bottom: 10px;
	}
	.pharmacy-booking .business-btn {
		margin-right: 0;
		margin-bottom: 0;
	}
	.pharmacy-booking .browse-products-btn {
		margin-bottom: 10px;
	}
	.feedback-btn .btn {
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: 991.98px) {
	
	.nav-bg .main-nav li .submenu a:hover {
		padding: 10px 15px 10px 35px;
	}
	.nav-bg-two .main-nav li .submenu a:hover {
		padding: 10px 15px 10px 35px;
	}
	.home-one-banner {
	    padding: 0 0 30px;
	}
	.home-three-nav .main-nav .has-submenu.active > a:after {
		display: none;
	}
	.bg-shapes {
		display: none;
	}
	.main-nav {
		padding: 0;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
	.doc-info-left.doc-details,
	.doc-info-right.appt-date {
		width: 100%;
	}
	.doc-info-right.appt-date {
		margin-left: unset;
		margin-top:20px;
		justify-content: center;
		display: flex;
		flex-direction: column;
	}
	.doctor-col{
		flex-direction: column;
	}
	.blog-pagination .pagination li a {
		padding: 10px 15px;
	}
	.nav-bg .main-menu-wrapper {
	    display: unset;
	}
	.nav-bg .menu-close:hover {
		color: #0071dc;
	}
	.nav-bg .main-menu-wrapper {
		background-color: #0071DC;
	}
	.nav-bg .main-nav li .submenu a:hover {
		color: #fff;
	}
	.nav-bg .main-nav .has-submenu.active > a {
	    color: #fff;
	}
	.nav-bg .main-nav .has-submenu.active .submenu li.active > a {
	    color: #fff;
	}
	.nav-bg .login-blue-bg {
		display: none;
	}
	.nav-bg .signup-white-bg {
		display: none;
	}
	.nav-bg .main-nav li.login-link {
	    display: block;
	}
	.nav-bg .logo {
		width: 100%;
		margin: 0;
	}
	.nav-bg-five .main-menu-wrapper {
		background-color: #0071DC;
	}
	.nav-bg-five .black-font > li > a {
		color: #fff;
	}
	.nav-bg-five .black-font > li > a:hover {
		color: #fff;
	}
	.nav-bg-five .main-nav .has-submenu.active > a {
	    color: #fff;
	}
	.nav-bg-five .main-nav .has-submenu.active .submenu li.active > a {
		color: #fff;
	}
	.nav-bg-fifteen .main-menu-wrapper {
		background-color: #0071DC;
	}
	.nav-bg-fifteen .black-font > li > a {
		color: #fff;
	}
	.nav-bg-fifteen .black-font > li > a:hover {
		color: #fff;
	}
	.nav-bg-fifteen .main-nav .has-submenu.active > a {
	    color: #fff;
	}
	.nav-bg-fifteen .main-nav .has-submenu.active .submenu li.active > a {
		color: #fff;
	}
	.menu-close:hover {
		color: #0071dc;
	}
	.nav-bg .navbar-header {
		width: 100%;
		text-align: center;
	}
	.main-nav .has-submenu.active > a {
		color: #fff;
	}
	.main-nav .has-submenu > a:hover {
		color: #fff;
	}
	.home-three-nav .black-font > li > a {
	    color: #fff;
	}
	.banner-left .banner-info h2 {
		font-size: 36px;
		margin-bottom: 10px;
	}
	.banner-left .banner-details h4 {
		margin-right: 10px;
		font-size: 20px;
	}
	.banner-left .banner-details p {
		font-size: 18px;
	}
	.main-nav ul {
		background-color: #3474ac;
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;
	}
	.main-nav > li {
		border-bottom: 1px solid #8bbeef;
		margin-left: 0;
	}
	.main-nav li + li {
		margin-left: 0;
	}
	.main-nav > li > a {
		line-height: 1.5;
		padding: 15px 20px !important;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	.main-nav > li > a > i {
		float: right;
		margin-top: 5px;
	}
	.main-nav > li .submenu li a {
		border-top: 0;
		color: #fff;
		padding: 10px 15px 10px 35px;
	}
	.main-nav > li .submenu ul li a {
		padding: 10px 15px 10px 45px;
	}
	.main-nav > li .submenu > li.has-submenu > a::after {
		content: "\f078";
	}
	.main-nav .has-submenu.active > a {
		color: #fff;
	}
	.main-nav .has-submenu > a:hover {
		color: #fff;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #fff;
	}
	.login-left {
		display: none;
	}
	.main-menu-wrapper {
		order: 3;
		width: 260px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1060;
		transform: translateX(-260px);
		transition: all 0.4s;
		background-color: #0071DC;
	}
	.menu-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	#mobile_btn {
		display: inline-block;
	}
	.section-search {
		min-height: 330px;
	}
	.section-specialities {
		padding: 50px 0;
	}
	.footer-widget {
		margin-bottom: 30px;
	}
	.chat-cont-left, .chat-cont-right {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-cont-left {
		border-right: 0;
	}
	.chat-cont-right {
		position: absolute;
		right: -100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
	}
	.chat-cont-right .chat-header {
		justify-content: start;
		-webkit-justify-content: start;
		-ms-flex-pack: start;
	}
	.chat-cont-right .chat-header .back-user-list {
		display: block;
	}
	.chat-cont-right .chat-header .chat-options {
		margin-left: auto;
	}
	.chat-window.chat-slide .chat-cont-left {
		left: -100%;
	}
	.chat-window.chat-slide .chat-cont-right {
		right: 0;
		opacity: 1;
		visibility: visible;
	}
	.day-slot li.left-arrow {
		left: -10px;
	}
	.container {
		max-width: 100%;
	}
	.appointments .appointment-action {
		margin-top: 10px;
	}
	.appointments .appointment-list {
		display: block;
	}
	.banner-wrapper {
		max-width: 720px;
	}
	.search-box .search-info {
		-ms-flex: 0 0 180px;
		flex: 0 0 180px;
		width: 180px;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 2.125rem;
	}
	.dct-border-rht {
		border-bottom: 1px solid #f0f0f0;
		border-right: 0;
		margin-bottom: 20px;
		padding-bottom: 15px;
	}
	.dr-img {
		display: none;
	}
	.doctor-search {
		padding: 30px 0;
	}
	.doctor-search-section {
		padding-top: 85px;
	}
	.shapes {
		display: none;
	}
	.search-box-3 {
		position: relative;
	} 
	.header-top {
		display: none;
	}
	.search-box {
		max-width: 535px;
		margin: 0 auto;
	}
	.card-label > label {
		font-size: 12px;
	}
	.footer .footer-top {
		padding-bottom: 10px;
	}
	.time-slot li .timing.selected::before {
		display: none;
	}
	.review-listing .recommend-btn {
		float: none;
	}
	.dash-widget {
		flex-direction: unset;
		text-align: left;
	}
	.circle-bar {
		margin: 0 15px 0 0;
	}
	.call-wrapper {
		height: calc(100vh - 140px);
	}
	.sidebar-overlay.opened {
		display: block;
	}
	.about-content {
		margin-bottom: 30px;
	}
	.slide-image{
		padding-left: 0;
		padding-right: 0;
	}
	.custom-short-by > div{
		margin-right: 0;
	}
	.product-description .doctor-img1{
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
	}
	.product-description .doc-info-cont{
		width: 100%;
	}
	.product-description .doc-info-left{
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
	}
	.clinics-section {
		padding: 40px 0 25px;
	}
	.specialities-section {
		padding: 60px 0 35px;
	}
	.our-doctors-section {
		padding: 40px 0;
	}
	.clinic-features-section {
		padding: 40px 0;
	}
	.our-blog-section {
		padding: 40px 0;
	}
	.section-search-3 .banner-info {
		padding: 60px 0 130px;
	}	
	.section-search-3 .doctor-search-form .banner-btn {
		padding: 18px 30px;
	}
	.pop-box .body-section h3 {
		font-size: 20px;
	}
	.section-header-three h2 {
		font-size: 28px;
	}
	.doc-background, .pat-background {
		min-height: 250px;
	}
	.doctor-divison h3 {
		font-size: 20px;
	}
	.doctor-divison p {
		font-size: 12px;
	}
	.doctors-body .inner-section > span {
		font-size: 12px;
	}
	.book-best-doctors .doctors-body .average-ratings {
		padding: 1px 8px;
		font-size: 10px;
		margin-left: 7px;
	}
	.book-best-doctors .doctors-body h4 {
		font-size: 18px;
	}
	.book-best-doctors .amt-txt {
		font-size: 16px;
	}
	.latest-blog .blog-wrap .blog-wrap-body h3 {
		font-size: 18px;
	}
	.header-four .nav-item a.header-login {
		margin: 15px 20px;
	}
	.header-four .nav-item a.header-login i {
		float: unset;
	}
	.doc-form {
		position: absolute;
		top: 85px;
		left: 20px;
    	padding: 37px 0;		
		width: 600px;
	}
	.dot-slider .slick-slide {
		height: 375px;
	}
	.dot-slider .profile-widget {
		margin-bottom: 0;
	}
	.app-form h2 {
		font-size: 30px;
	}
	.app-form .doctor-search-form input {
		min-height: 50px;
	}
	.app-form .banner-btn {
		padding: 10px 30px;
		min-height: 50px;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.category-sec {
		padding: 50px 0;
		margin-top: 0;
	}
	.set-category {
		padding: 10px;
	}
	.set-category .info-holder h3 {
		font-size: 20px;
	}
	.set-category .info-holder p {
		font-size: 11px;
	}
	.set-category .img-holder {
		margin-right: 10px;
	}
	.set-category .img-holder a {
		width: 50px;
		height: 50px;
	}
	.set-category .img-holder a img {
		width: 20px;
	}
	.select-box {
		padding: 20px;
	}
	.select-box p {
		font-size: 14px;
		margin-bottom: 20px;
	}
	.select-box .book-now {
		padding: 8px 20px;
	}
	.select-box .image-holder {
		margin-right: 15px;
	}
	.aval-features .custom-contain {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
	.aval-features .feature {
		align-items: center;
		justify-content: center;
		display: flex;
		padding: 40px;
	}
	.aval-features .feature .info-box {
		padding: 20px;
	}
	.aval-features .feature .info-box h2 {
		font-size: 30px;
	}
	.aval-features .custom-contain {
		max-width: 100%;
	}
	.header-trans.header-five .main-menu-wrapper {
		margin-left: auto;
	}
	.header-trans.header-trans-two .main-menu-wrapper {
		margin-left: auto;
	}
	.home-search-section .banner-header h2 {
		font-size: 34px;
	}
	.facility-section .operat-img {
		width: 100%;
	}
	.facility-section .visit-doctor {
		padding: 15px;
		margin-bottom: 20px;
	}
	.facility-section .visit-doctor .inner-details img {
		bottom: -13px;
	}
	.visit-doctor .inner-details .info h1 {
		font-size: 20px;
	}
	.visit-doctor .inner-details .info p {
		font-size: 12px;
		max-width: 100%;
	}
	.visit-doctor .inner-details .count h1 {
		width: 55px;
		height: 55px;
		font-size: 20px;
	}
	.clinic-wrap {
		height: 200px;
	}
	.heading-wrapper h1 {
		font-size: 30px;
		max-width: 100%;
	}
	.clinic-wrap .wrapper-overlay img {
		margin-bottom: 20px;
	}
	.doc-booking .book-slider .slick-prev, .doc-booking .book-slider .slick-next {
		top: -100px;
	}
	.blog-wrapper .wrap-content .date-cart, .pharm-slide-home h6 {
		font-size: 26px;
	}
	.home-search-section .doctor-search {
		padding: 40px 0;
	}
	.facility-section {
		padding: 40px 0 20px;
	}
	.clinic-speciality {
		padding: 40px 0 10px;
	}
	.browse-section.brower-category {
		padding: 40px 0 20px;
	}
	.doctor-divison.provider-select .doc-background, .doctor-divison.provider-select .pat-background {
		padding: 40px 30px;
	}
	.doc-booking {
		padding: 40px 0;
	}
	.blogs-section.blog-container {
		padding: 40px 0 10px;
	}
	.heading-wrapper .nav-arrow {
		display: none;
	}
	.heading-wrapper {
		margin-bottom: 30px;
		text-align: center;
	}
	.blog-wrapper .wrap-content:before {
		border-left: 178px solid transparent;
		border-right: 170px solid transparent;
	}	
	.divider .carousel-inner {
		height: 430px;
	}
	.about-section {
		min-height: unset;
		background: linear-gradient(360deg, rgba(0, 113, 220, 0.06) 40.94%, rgba(229, 241, 251, 0) 100%);
	}
	.select-category {
		padding: 50px 0 20px;
		margin: 0;
	}
	.contact-section h3 {
		font-size: 36px;
	}
	.contact-section p {
		font-size: 18px;
	}
	.contact-box .infor-details p {
		font-size: 14px;
	}
	.category-subox {
		margin-bottom: 30px;
	}
	.category-col:after {
		bottom: 15px;
	}
	.appoinment-box .appoinment-btn {
		margin-right: 20px;
    	width: 50px;
	}
	.appointment-info-holder h2 {
		font-size: 38px;
	}
	.appointment-info .appointment-link {
		font-size: 24px;
	}
	.appointment-info-holder h2 {
		font-size: 28px;
	}
	.news-bg .news-left h2 {
		font-size: 36px;
	}
	.nav-bg .header-nav {
		height: 85px;
	}
	.appointment-bg {
	    padding: 20px 0 20px;
	}
	.appointment-bg .appointment-left {
		display: none;
	}
	.appointment-grid {
		margin-bottom: 20px;
	}
	.service-section {
		padding: 40px 0 40px; 
	}
	.doctor-section {
	    padding: 40px 0 40px;
	}
	.section-header {
	    margin-bottom: 40px;
	}
	.features-section {
	    padding: 40px 0 40px;
	}
	.news-letter-bg {
	    padding: 40px 0 40px;
	}
	.news-section {
		padding: 0px 0 40px;
	}
	.news-letter-bg .news-bg {
		text-align: center;
		padding: 40px 0
	}
	.news-img {
		display: none;
	}
	.grid-news .news-content .news-title a {
		font-size: 18px;
	}
	.grid-news .news-content ul li .news-date a {
		font-size: 15px;
		height: 40px;
	}
	.footer-one {
		padding: 20px 0;
	}
	.footer-two {
		padding: 20px 0;
	}
	.nav-bg-two .main-menu-wrapper {
		background-color: #0071DC;
	}
	.nav-bg-two .main-nav .has-submenu.active > a {
		color: #fff;
	}
	.nav-bg-two .main-nav > li {
		border-bottom: 1px solid #8bbeef;
	}
	.nav-bg-two .main-nav li .submenu a:hover {
		color: #fff;
	}
	.nav-bg-two .main-nav .has-submenu.active .submenu li.active > a {
	    color: #fff;
	}
	.nav-bg  .main-nav > li {
	    border-bottom: 1px solid #8bbeef;
	}
	.nav-bg-two .menu-close:hover {
		color: #0071dc;
	}
	.home-banner-two {
		padding: 80px 0 0px;
	}
	.home-banner-two .slick-slide img {
		height: auto;
	}
	.banner-two-form {
		margin: -50px auto 0;
		position: relative;
		top: 0;
		left: 0;
		-webkit-transform: translateY(0%);
    	transform: translateY(0%);
	}
	.banner-two-form .search-area {
		max-width: 100%;
	}
	.home-banner-two .slider-counter {
	    position: relative;
	    right: 0;
	    float: unset;
	    font-size: 20px;
	    padding: 10px;
	    bottom: 5px;
	    min-width: 200px;
	    display: none;
	    justify-content: center;
	    align-items: center;
	    left: 15px;
	    text-align: center;
	    margin: auto;
	}
	.home-banner-two .slick-prev {
	    right: auto !important;
	    top: 50%;
	    left: 15px;
	}
	.home-banner-two .slick-next {
	    right: 15px !important;
	    top: 50%;
	    left: auto;
	}
	.looking-section {
		padding: 40px 0 20px;
	}
	.looking-big-icon {
		font-size: 100px;
	}
	.best-doctor-section {
		padding: 0px 0 40px;
	}
	.best-doctor-widget {
		margin-bottom: 40px;
	}
	.clinic-grid img {
		width: 100px;
	}
	.features-clinic-section {
		padding: 40px 0 40px;
	}
	.high-service-section {
		padding: 40px 0 40px;
	}
	.blog-news-section {
		padding: 0px 0 40px;
	}
	.blog-news-grid {
		margin-bottom: 20px;
	}
	.blog-news-section .high-btn {
		margin-top: 20px;
	}
	.footer-news {
		margin-top: 40px;
		margin-bottom: 0;
	}
	.footer-news .footer-news-head h2 {
		font-size: 36px;
	}
	.banner-three-content {
		display: none;
	}
	.home-banner-three .banner-wrapper {
		max-width: unset;
	    position: relative;
	    top: 0;
	    padding: 40px 0px;
	    left: 0;
	    -webkit-transform: translateY(0%);
    	transform: translateY(0%);
	}
	.home-banner-three .app-form {
		display: inline-block;
	}
	.home-banner-three .banner-wrapper .app-form-one {
		display: inline-block;
		background: #063B6F;
		width: 100%;
		padding: 20px;
	}
	.home-banner-three .d-md-none {
		display: none;
	}
	.home-banner-three .owl-nav {
		display: none;
	}
	.home-banner-three .owl-theme .owl-dots {
	    right: 0 !important;
	    left: 0;
	    max-width: max-content;
	    text-align: center;
	    bottom: 10px;
	}
	.looking-section-three {
		padding: 20px 0 20px;
	}
	.header-three {
		margin-bottom: 40px;
	}
	.header-three h2 {
		font-size: 36px;
	}
	.header-three h2:before {
		width: 75px;
	}
	.header-three h2:after {
		width: 75px;
	}
	.browse-section-three {
		padding: 40px 0 40px;
	}
	.browse-grid {
		margin-bottom: 20px;
	}
	.doctor-details-section {
		padding: 60px 0 60px;
	}
	.doctor-section-three {
		padding: 40px 0 40px;
	}
	.best-our-doctors {
		margin-bottom: 40px;
	}
	.features-clinic {
		padding: 40px 0 40px;
	}
	.features-clinic-slider {
		margin-bottom: 40px;
	}
	.latest-blog-section {
		padding: 40px 0 40px;
	}
	.latest-blog-grid {
		margin-bottom: 40px;
	}
	.news-letter-section {
		padding: 40px 0 40px;
	}
	.news-letter-section .news-left .news-info h2 {
		font-size: 36px;
	}
	.footer-three {
		padding: 20px 0;
	}
	.home-four-banner {
		padding: 0;
	}
	.home-four-banner .header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-color: #fff;
	}
	.home-four-top {
		display: none;
	}
	.home-four-doctor .home-four-header h2 {
		font-size: 36px;
	}
	.looking-section-four {
	    padding: 0px 0 0px;
	}
	.section-header-four {
		margin-bottom: 40px;
	}
	.section-header-four h2 {
		font-size: 36px;
	}
	.clinic-section-four {
		padding: 40px 0 0px;
	}
	.clinic-grid-four {
		margin-bottom: 20px;
	}
	.doctor-section-four {
		padding: 40px 0 40px;
	}
	.best-doctors-grid {
		margin-bottom: 30px;
	}
	.features-clinic-four {
		padding: 40px 0 40px;
	}
	.clinic-big-img {
		display: none;
	}
	.features-clinic-slider-four {
		margin-bottom: 20px;
	}
	.blog-section-four {
		padding: 40px 0 40px;
	}
	.grid-blog-four {
		margin-bottom: 30px;
	}
	.news-section-four {
		padding: 40px 0 40px;
	}
	.news-section-four .news-info h2 {
		font-size: 36px;
	}
	.home-section-five {
		padding: 60px 0;
	}
	.section-search-five h2 {
		font-size: 40px;
	}
	.looking-section-five {
		padding: 40px 0;
	}
	.looking-bg-five {
		padding-bottom: 0;
	}
	.looking-grid-five {
		margin-bottom: 30px;
	}
	.clinic-section-five {
		padding: 40px 0 40px;
	}
	.doctor-section-five {
		padding: 40px 0 40px;
	}
	.doctor-img2 {
		display: none;
	}
	.browse-section-five {
		padding: 40px 0 20px;
	}
	.specialist-card-five {
		margin-bottom: 20px;
	}
	.best-section-five {
		padding: 40px 0 40px;
	}
	.doctor-see-btn {
		margin-top: 30px;
	}
	.news-letter-five {
	    padding: 20px 0;
	}
	.blog-section-five {
		padding: 40px 0;
	}
	.blog-grid-five {
		margin-bottom: 20px;
	}
	.scroll-down {
		display: none;
	}
	.looking-row {
		padding-left: 15px;
		padding-right: 15px;
		border: none;
	}
	.looking-col {
	    padding-left: 15px;
	    padding-right: 15px;
	}
	.looking-grid-four {
		margin-bottom: 20px;
		padding: 0;
	    border: 1px solid rgba(192, 201, 213, 0.5);
	}
	.looking-grid-four:hover .looking-box-four {
		top: 0;
		right: 0;
	}
	.home-banner-two .slider-counter-three {
		display: none;
	}
	.section-category {
		padding: 40px 0;
	}
	.section-blogs {
		padding: 40px 0;
	}
	.divider .banner-wrapper {
		padding-bottom: 0;
	}
	.appoint {
		font-size: 22px;
	}
	.view-icons a {
		font-size: 18px;
		width: 40px;
		height: 40px;
	}
	.total-dr {
		font-size: 16px;
	}
	.calender {
		font-size: 14px;
	}
	.doctor-widget .doc-name {
		font-size: 18px;
	}
	.doc-speciality {
		font-size: 12px;
	}
	.doc-department h6 {
		font-size: 12px;
	}
	.left-panel{
		display: none;
	}
	.right-panel{
		width: 100%;	
		height: auto;
		padding: 0px;
	}
	.right-panel-title{
		display: block;
	}
	.right-panel-title img{
		max-width: 140px;
	}
	.onboarding-list h6 {
		font-size: 15px;
	}
	.onboarding-progress span{
		font-size: 12px;
	}
	.onboarding-list p{
		font-size: 13px;
	}
	.onboarding-title h2{
		font-weight: 450;
    	font-size: 22px;
	}
	.onboarding-lable{
		font-size: 13px;
	}
	.passcode-wrap.mail-box input{
		font-size: 14px;
		height: 50px;
	}
	.right-panel .select2-container .select2-selection--single{
		height: 50px;
	}
	.right-panel .select2-container--default .select2-selection--single .select2-selection__rendered{
		line-height: 50px;
	}
	.right-panel .select2-container--default .select2-selection--single .select2-selection__arrow{
		height: 50px;
	}
	.passcode-wrap.mail-box textarea{
		height: 100px;
	}
	.onboarding-btn a{
		padding: 13px 45px;
		font-size: 14px;
	}
	.onboarding-content-box{
		margin-top: 0px;
	}
	.onboarding-btn {
		margin-top: 75px;
	}
	.passcode-wrap.form-focus .focus-label {
		top: -8px;
	}
	.onboarding-content-box {
		padding: 30px;
	}
	.passcode-wrap input{
		width: 77px;
    	height: 50px;
		font-size: 24px;
		font-weight: 400;
	}
	.method-btn a{
		font-size: 12px;
	}
	.code-title{
		padding: 10px 12px;
		margin: 22px 0px;
	}
	.code-title h6{
		font-size: 14px;	
	}
	.onboarding-btn.step-2-btn{
		margin-top: 50px;
	}
	.weekDays-selector input[type=checkbox] + label{
		font-size: 16px;
		padding: 14px 22px;
	}
	.onboard-wrapper {
		height: auto;
	}
	.modal.show .modal-dialog.id-upload {
		max-width: 400px;
	}
	.modal-header.id-pop-header{
		padding: 5px;
	}
	.modal-body.id-pop-body {
		padding: 5px;
	}
	.modal-footer.id-pop-footer {
		border-top: none;
		padding: 2px 0px 5px 0px;
	}
	.modal-body.id-pop-body h2{
		font-size: 20px;
	}
	.modal-body.id-pop-body p{
		font-size: 12px;
	}
	.call-lable.verify-lable.verify-lable{
		height: 120px;
	}
	.upload-sucess-note h6{
		font-size: 14px;
	}
	.upload-sucess-note h6:nth-child(2){
		font-size: 14px;
	}
	.payment-icon img{
		top: -168px;
    	right: 10px;
	}
	.tog-slider:before{
		height: 12px;
    	width: 12px;
	}
	.tog-slider.round{
		width: 50px;
		height: 22px;
	}
	.save-card h6{
		font-size: 13px;
		margin-bottom: 0px;
	}
	.prefer-box{
		padding: 24px;
		font-size: 14px;
	}	
	.onboarding-btn.Consultation-btn.cost-btns {
		margin-top: 10px;
	}
	.call-lable{
		font-size: 14px;
		width: 172px;
	}
	.call-option{
		width: max-content;
	}
	.onboard-page{
		overflow: auto;
	}
	.on-board-wizard{
		padding-top: 25px;
		top:50px;
	}
	.onboarding-title h6{
		font-size: 14px;
	}
	.dash-widget-info h3{
		font-size: 22px;
	}
	.dash-cards table.table td h2 a{
		font-size: 14px;
	}
	.avatar-m {
		height: 50px;
		width: 50px;
	}
	.dash-cards .table.table-center td, .table.table-center th{
		white-space: nowrap;
	}
	.dash-cards .select2-container--default .select2-selection--single .select2-selection__rendered{
		font-size: 14px;
	}
	.earning-details h2 {
		font-weight: 500;
		font-size: 31px;
	}
	.onboarding-content-box.content-wrap{
		min-height: auto;
	}
	.opt-resend {
		padding-top: 20px;
	}
	.opt-resend a {
		left: 0px;
	}
	.home-fifteen-banner {
		display: none;
	}
	.title-block {
		align-items: center;
	}
	.make-appointment-btn {
		justify-content: center;
	}
	.home-section-fifteen {
		padding: 50px 0px;
	}
	.make-appointment-btn a, .que-wrap ul li a {
		font-size: 16px;
	}
	.title-block h2 {
		font-size: 40px;
	}
	.home-fifteen-looking-section {
		padding: 40px 0px;
	}
	.home-fifteen-section-title h2 {
		font-size: 40px;
	}
	.home-fifteen-looking-section .home-fifteen-section-title {
		margin-bottom: 70px;
	}
	.looking-for-box {
		padding: 30px;
	}
	.looking-for-box h5 {
		font-size: 28px;
	}
	.looking-for-box a {
		padding: 10px 20px;
	}
	.looking-for-container:nth-child(3) {
		margin-top: 0px;
	}
	.looking-for-container {
		margin-top: 20px;
	}
	.home-fifteen-why-us {
		padding: 40px 0px;
	}
	.why-us-container-img {
		display: none;
	}
	.why-us-paragraph p, .pharm-grid-blog .blog-content h3 {
		font-size: 16px;
	}
	.why-us-points ul li, .pharm-slide-home p {
		font-size: 16px;
	}
	.home-fifteen-book-doctor {
		padding: 40px 0px; 
	}
	.home-fifteen-view-btn {
		padding-top: 35px;
	}
	.home-fifteen-features {
		padding: 40px 0px;
	}
	.features-container:nth-child(1),
	.features-container:nth-child(2),
	.features-container:nth-child(3) {
		margin-bottom: 20px;
	}
	.newsletter-container-img {
		display: none;
	}
	.newsletter-container {
		padding: 40px 0px;
	}
	.newsletter-content-box h3, .pharm-slide-home .slide-image h2 span {
		font-size: 38px;
	}
	.newsletter-content-box p {
		font-size: 17px;
	}
	.newsletter-content-box {
		padding-top: 0px;
	}
	.home-fifteen-blog-section {
		padding: 40px 0px;
	}
	.blog-inner-box {
		margin-top: 20px;
	}
	.blog-container-box .home-fifteen-view-btn {
		padding-top:15px
	}
	.section-header h2 {
		font-size: 30px;
	}
	.home-fifteen-features .home-fifteen-view-btn {
		padding-top:15px
	}
	.foot-social-icons {
		margin-bottom: 30px;
	}
	.home-fifteen-footer {
		padding: 40px 0px;
	}
	.foot-line {
		margin: 20px 0px 20px 0px;
	}
	.pharm-slide-home .slide-image h2 {
		font-size: 50px;
	}
	.pharm-slide-home .slide-image {
		padding: 50px 0;
	}
	.pharm-slide-home {
		background-size: 99px, 250px, 100px, auto;
	}
	.offer-widget h3 {
		font-size: 32px;
	}
	.offer-widget a {
		font-size: 15px;
		padding: 10px 15px;
	}
	.pharm-grid-blog .blog-date ul li {
		margin-right: 8px;
	}
	.que-wrap h2 {
		font-size: 28px;
	}
	.pharm-grid-blog .btn {
		font-size: 16px;
		padding: 11px;
	}
	.section-client, .section-question, .section-feature, .section-blog,
	.section-deals {
		padding: 40px 0;
	}
	.section-blog {
		padding: 40px 0 20px;
	}
	.pharm-grid-blog {
		margin-bottom: 20px;
	}
	.popular-category {
		padding-bottom: 10px;
	}
	.section-categories {
		padding: 40px 0 20px;
	}
	.offer-widget {
		margin-bottom: 20px;
	}
	.pharm-grid-blog .blog-content h3 {
		margin-bottom: 15px;
	}
	.pharm-slide-home .slide-image .book-btn1 {
		padding: 10px 20px;
		font-size: 16px;
	}
	.pro-list .deal-pro-img {
		width: 250px;
	}
	.pro-list .deal-pro-detail h4 {
		font-size: 20px;
	}
	.breadcrumb-bar-six .breadcrumb-title {
	    font-size: 28px;
	}
	.breadcrumb-bar-six .page-breadcrumb ol {
	    font-size: 14px;
	}
	.related-products-section {
	    padding: 50px 0 0;
	}
	.cart-table .table td, .cart-table .table th {
		font-size: 16px;
	}
	.returning-box {
		padding: 20px;
		margin-bottom: 30px;
	}
	.returning-box p {
		font-size: 16px;
	}
	.billing-card .card-header .card-title {
		font-size: 20px;
	}
	.booking-subtotal ul li .total-amount {
		font-size: 20px;
	}
	.payment-success-card .card-body {
		padding: 20px;
	}
	.payment-success-cont i {
		font-size: 26px;
	}
	.payment-success-cont h3 {
		font-size: 20px;
	}
	.order-info .nav li {
	    padding-right: 15px;
	    font-size: 14px;
	} 
	.order-card-header {
	    margin-bottom: 20px;
	}
	.order-card-header h4 {
	    font-size: 20px;
	}
	.order-table-card .table td h2 a {
		font-size: 16px;
	}
	.pharm-register .login-header h3 {
		font-size: 20px;
	}
	.doctor-widget .doc-name {
	    font-size: 22px;
	}
	.pharmacy-booking .view-details-btn {
	    font-size: 14px;
	    padding: 10px 20px;
	}
	.pharmacy-booking .browse-products-btn {
	    font-size: 14px;
	    padding: 10px 20px;
	}
	.pharmacy-widget .doc-name {
		padding-top: 10px;
	}
	.pharmacy-social-links {
		padding-top: 0;
		padding-bottom: 10px;
	}
	.pharmacy-booking-right .btn i {
		font-size: 18px;
	}
	.pharmacy-details-info .widget {
	    margin-bottom: 30px;
	}
	.pharmacy-details-info .widget-title {
		font-size: 20px;
		margin-bottom: 20px;
	}
	.pharmacy-business-hours h4 {
		font-size: 20px;
	}
	.pharmacy-review-box {
		padding: 20px;
	}
	.review-based {
		margin-bottom: 20px;
	}
	.review-based::before {
		display: none;
	}
	.review-based h2 {
		font-size: 60px;
	}
	.review-based .rating i.filled {
	    font-size: 18px;
	}
	.review-based p {
	    font-size: 18px;
	}
	.review-progress-percent p {
		font-size: 20px;
	}
	.pharmacy-comments-list .comment {
		padding: 25px;
	}
	.pharmacy-comments-list .sub-comment {
		padding-bottom: 1px;
	}
	.feedback-btn .btn {
		margin-bottom: 0;
	}
	.pharmacy-write-header h4 {
		font-size: 24px;
	}
	.pharm-slide-home {
		border-radius: 0;
	}
}

@media only screen and (max-width: 849.98px) {
	
	.row.row-grid > div {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media only screen and (max-width: 767.98px) {
	
	.grid article {
		width: 48%;
	  }
	
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.375rem;
	}
	h4, .h4 {
		font-size: 1rem;
	}
	h5, .h5 {
		font-size: 0.875rem;
	}
	h6, .h6 {
		font-size: 0.75rem;
	}
	.content {
		padding: 15px 0 0;
	}
	
	.aval-features .feature .info-box a {
		font-size: 12px;
		width: 100px;
	}
	.aval-features .col-md-6.ps-0 {
		padding-left: 15px !important;
	}
	.aval-slider .slick-list {
		padding: 30px 0;
	}
	.blogs-section {
		padding: 30px 0;
	}
	.blog-wrapper .wrap-content .date-cart {
		font-size: 22px;
		width: 60px;
    	height: 55px;
		padding: 5px;
	}
	.blog-wrapper .wrap-content .date-cart span {
		font-size: 10px;
	}
	.blog-wrapper .wrap-content-body h3 {
		font-size: 17px;
	}	
	.home-search-section .banner-header h2 {
		font-size: 30px;
	}
	.heading-wrapper h1 {
		font-size: 24px;
	}	
	.clinic-wrap .wrapper-overlay h3 {
		font-size: 20px;
		text-align: center;
	}
	.facility-section .operat-img {
		width: 100%;
		margin-bottom: 20px;
	}
	.visit-doctor .inner-details .info h1, .visit-doctor .inner-details .count h1 {
		font-size: 18px;
	}	
	.clinic-wrap .wrapper-overlay img {
		margin: 0 auto 0;
	}
	.clinic-wrap .wrapper-overlay p {
		text-align: center;
	}
	.doc-booking .book-slider .slick-list {
		padding-top: 50px;
	}
	.doc-booking .book-slider .slick-prev, .doc-booking .book-slider .slick-next {
		top: 0;
	}	
	.book-slider .provider-info h3 a {
		font-size: 20px;
	}
	.blog-container .blog-wrapper .wrap-content-body h3 a {
		font-size: 17px;
	}
	.blog-wrapper .wrap-content:before {		
		border-left: 170px solid transparent;
		border-right: 160px solid transparent;
	}
	.pop-box {
		margin-bottom: 30px;
	}
	.about-section p, .testimonial-item .testimonial-content p {
		font-size: 14px;
	}
	.about-section {
		min-height: unset;
	}
	.about-titile {
		font-size: 13px;
	}
	.about-section h3 {
		font-size: 20px;
	}	
	.category-subox p {
		font-size: 14px;
	}
	.contact-box {
		margin-bottom: 30px;
	}
	.contact-section {
		padding-bottom: 20px;
	}
	.category-subox h4 {
		font-size: 16px;
	}
	.contact-box .infor-details {
		padding-top: 65px;
	}
	.category-subox {
		margin-bottom: 30px;
	}
	.contact-section h3 {
		font-size: 1.5rem;
	}
	.contact-section p {
		font-size: 16px;
	}
	.banner-left .banner-info h2 {
		font-size: 26px;
	}
	.banner-left .banner-details {
	    padding-top: 10px;
	}
	.banner-left .banner-details h4 {
		font-size: 15px;
		margin-bottom: 10px;
	}
	.banner-left .banner-details p {
		font-size: 15px;
	}
	.appoinment-box .appoinment-right {
		display: block;
	}
	.appoinment-box .appoinment-btn {
	    margin-right: 10px;
	}
	.appointment-info .appointment-link {
		font-size: 18px;
	}
	.schedule-title {
		font-size: 18px;
	}
	.appointment-img-holder {
		margin-right: 10px;
	}
	.appointment-img-holder img {
		width: 50px;
	}
	.appointment-info-holder h2 {
		font-size: 20px;
	}
	.appointment-info p {
		font-size: 15px;
	}
	.appointment-info .appointment-read-btn {
		font-size: 15px;
	}
	.appointment-schedule {
		margin-bottom: 20px;
	}
	.appointment-request {
		margin-bottom: 20px;
	}
	.doctor-profile-widget {
		margin-bottom: 40px;
	}
	.doctor-profile-widget:hover {
		box-shadow: 0px 15px 20px rgb(0 28 43 / 10%);
	}
	.slick-view-btn .btn {
		padding: 8px 20px;
		font-size: 14px;
	}
	.doctor-book-slider .slick-next {
	    top: 102.8%;
	}
	.doctor-book-slider .slick-prev {
	    top: 102.8%;
	}
	.news-bg .news-left h2 {
		font-size: 1.875rem;
	}
	.news-bg .news-left p {
		font-size: 15px;
	}
	.news-bg .news-left .form-control {
		width: 100%;
		margin-bottom: 20px;
	}
	.grid-news {
		margin-bottom: 20px;
	}
	.grid-news .news-content p a {
		font-size: 16px;
	}
	.news-section .view-more {
	    padding: 8px 20px;
	    font-size: 16px;
	}
	.footer-one {
		padding: 0;
	}
	.footer-two {
		padding: 0;
	}
	.home-banner-two .profile-widget {
		min-height: 200px;
	}
	.home-banner-two .slick-prev {
	    top: auto;
	}
	.home-banner-two .slick-next {
	    top: auto;
	}
	.banner-two-form .app-form {
		display: inline-block;
		width: 100%;
	}
	.banner-two-form .app-form h2 {
		font-size: 1.5em;
		line-height: unset;
		margin-bottom: 20px;
	}
	.banner-two-form .search-area {
		padding: 20px;
	}
	.banner-two-form .search-btn {
		font-size: 16px;
	}
	.banner-two-form label {
		font-size: 14px;
	}
	.looking-info .looking-link {
		font-size: 22px;
	}
	.btn-one {
		font-size: 14px;
	}
	.btn-two {
		font-size: 14px;
	}
	.theme-btn {
		font-size: 14px;
	}
	.btn-five{
		font-size: 14px;
	}
	.btn-six {
		font-size: 14px;
	}
	.clinic-grid {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.clinic-grid img {
	    width: 70px;
	}
	.clinic-grid h4 {
		font-size: 18px;
	}
	.high-service-section .high-btn {
		margin-top: 20px;
	}
	.blog-news-grid .blog-news-date a {
		font-size: 15px;
	}
	.blog-news-grid .blog-news-title a {
		font-size: 20px;
	}
	.footer-news {
	    padding: 30px;
	}
	.footer-news .footer-news-head h2 {
	    font-size: 28px;
	}
	.footer-news .footer-news-head p {
		font-size: 15px;
	}
	.footer-news .footer-news-form .btn {
	    position: relative;
	    width: 100%;
	    margin-top: 15px;
	}
	.home-banner-three .banner-wrapper .app-form label {
		font-size: 14px;
	}
	.home-banner-three .banner-wrapper .app-form .form-control {
		min-height: 44px;
		font-size: 14px;
	}
	.header-three h2 {
		font-size: 1.875rem;
	}
	.header-three h2:before {
	    width: 65px;
	}
	.header-three h2:after {
	    width: 65px;
	}
	.looking-box .icon-inner {
		font-size: 30px;
		width: 80px;
		height: 80px;
	}
	.looking-box .looking-info .looking-link {
		font-size: 24px;
	}
	.looking-box .looking-btn .btn {
		font-size: 15px;
	}
	.browse-section-three {
		padding: 40px 0 20px;
	}
	.doctor-details-section {
	    padding: 40px 0 20px;
	}
	.doctor-details-section .doctor-grid {
		margin-bottom: 20px;
	}
	.doctor-grid .doctor-details h4 {
		font-size: 18px;
	}
	.latest-blog-grid .lastest-blog-info .latest-blog-title a {
		font-size: 18px;
	}
	.latest-blog-grid .lastest-post-author a {
		font-size: 14px;
	}
	.news-letter-section {
		text-align: center;
	}
	.news-letter-section .news-letter-img {
		display: none;
	}
	.news-letter-section .news-left .news-info h2 {
	    font-size: 1.875rem;
	}
	.news-letter-section .news-left .news-info p {
		font-size: 14px;
	}
	.news-letter-section .news-info-one .form-control {
		min-height: 46px;
		font-size: 14px;
	}
	.news-letter-section .news-left .news-info .btn {
		min-width: auto;
	}
	.footer-three {
	    padding: 0px 0;
	}
	.footer-three .footer-middle .footer-middle-menu {
		padding: 30px 0 0;
	}
	.footer-three .footer-middle .footer-middle-menu .footer-menu-three {
	    margin-top: 0;
	}
	.home-four-banner {
		background-position: 20%;
	}
	.home-four-doctor {
		padding: 120px 0;
	}
	.home-four-doctor .home-four-header h2 {
	    font-size: 1.875rem;
	}
	.banner-four-form .form-control {
		min-height: 46px;
		font-size: 14px;
	}
	.banner-four-form .form-control::placeholder {
		font-size: 14px;
	}
	.banner-four-form .btn {
		padding: 11px 30px;
	}
	.select2-results__option[aria-selected] {
		font-size: 14px;
	}
	.section-header-four h2 {
		font-size: 1.875rem;
	}
	.section-header-four p {
		font-size: 0.875rem;
	}
	.section-header-four .sub-title {
		margin: 20px auto 0;
		font-size: 0.875rem;
	}
	.looking-grid-four .looking-box-four {
		padding: 15px;
	}
	.looking-box-four .looking-inner-box {
		padding: 15px;
	}
	.looking-box-four .looking-inner-box .looking-info-four a {
		font-size: 20px;
	}
	.looking-box-four .looking-inner-box .looking-info-four a i {
		font-size: 30px;
	}
	.looking-box-four .looking-inner-box .looking-four-btn a {
		font-size: 15px;
	}
	.grid-blog-four .blog-title-four .btn {
		font-size: 15px;
	}
	.grid-blog-four .blog-date-four a {
		font-size: 15px;
	}
	.grid-blog-four .blog-info-four a {
		font-size: 20px;
	}
	.grid-blog-four .blog-doctors-four a {
		font-size: 15px;
	}
	.grid-blog-four .blog-four-content p {
		font-size: 15px;
	}
	.news-section-four .news-info h2 {
		font-size: 1.5rem;
	}
	.news-section-four .news-info p {
		font-size: 15px;
	}
	.news-section-four .footer-news-four .form-control {
		font-size: 15px;
		min-height: 46px;
	}
	.news-section-four .footer-news-four .form-control::placeholder {
		font-size: 15px;
	}
	.news-section-four .footer-news-four .btn {
	    position: relative;
	    width: 100%;
	    margin-top: 15px;
	    height: 46px;
	    right: 0;
	}
	.news-section-four {
		padding: 0px 0 0;
	}
	.home-section-five {
		padding: 40px 0;
	}
	.search-box-five {
		margin-top: 20px;
	}
	.section-search-five h2 {
		font-size: 1.875rem;
		margin-bottom: 20px;
	}
	.section-search-five span {
		font-size: 16px;
	}
	.section-search-five p {
		font-size: 16px;
	}
	.search-box-five form {
	    display: block;
	    height: auto;
	    padding: 10px;
	}
	.search-box-five form > div {
	    width: 100%;
	    display: inline-block;
	}
	.search-input-five.line-five {
	    width: 100%;
	}
	.search-input-five.line-five::before {
		display: none;
	}
	.search-btn-five .btn {
		min-width: 100%;
	}
	.search-input-five .search-loc {
		padding: 0px 50px 0px 50px;
	}
	.search-input-five > i.compass-icon {
		left: 20px;
	}
	.section-header-five .title-five:before {
		display: none;
	}
	.section-header-five .title-five:after {
		display: none;
	}
	.looking-grid-five {
		padding-left: 15px;
		padding-right: 15px;
	}
	.looking-grid-five .looking-icon-five {
		width: 80px;
		height: 80px;
		font-size: 30px;
		top: -40px;
	}
	.looking-grid-five .looking-info-five a {
		font-size: 26px;
	}
	.clinic-grid-info p {
		font-size: 16px;
	}
	.clinic-grid-five .clinic-grid-info .clinic-five-btn .btn {
		font-size: 14px;
		padding: 8px 20px;
	}
	.clinic-see-btn {
		padding-top: 10px;
	}
	.clinic-see-btn .btn {
		min-width: 170px;
		padding: 8px 20px;
	}
	.doctor-grid-five .doctor-details {
		margin-bottom: 20px;
	}
	.doctor-grid-five .doctor-details-one {
		margin-bottom: 0;
	}
	.doctor-grid-five .doctor-details h4 {
		font-size: 18px;
	}
	.doctor-grid-five .doctor-details p {
		max-width: none;
	}
	.doctor-grid-five .doctor-details .btn {
		font-size: 14px;
		min-width: 120px;
		padding: 7px 20px;
	}
	.doctor-grid-five .doctor-details-one .btn {
		font-size: 14px;
		min-width: 120px;
		padding: 7px 20px;
	}
	.doctors-grid-five .best-doctors-info h3 a {
		font-size: 20px;
	}
	.doctors-grid-five .best-doctors-info .doctor-posting {
		font-size: 15px;
	}
	.doctors-grid-five .doctors-btn-five .btn {
		font-size: 14px;
	}
	.doctor-see-btn {
	    margin-top: 20px;
	}
	.doctor-see-btn .btn {
	    min-width: 170px;
	    padding: 8px 20px;
	}
	.news-five {
		padding: 40px 0;
	}
	.news-five-head h2 {
		font-size: 1.875rem;
	}
	.news-five-head {
		padding-left: 15px;
		padding-right: 15px;
	}
	.news-five-form {
		padding-left: 15px;
		padding-right: 15px;
	}
	.news-five-form .form-control {
		width: 100%;
		margin-bottom: 20px;
		min-height: 46px;
	}
	.news-five-form .btn {
		font-size: 14px;
		padding: 10px 38px;
    	min-width: 150px;
	}
	.news-five-head p {
		font-size: 14px;
	}
	.footer .footer-menu ul li a {
		font-size: 14px;
	}
	.footer-five .footer-title {
	    font-size: 1.125rem;
	    margin-bottom: 20px;
	}
	.footer .footer-bottom .copyright-text p {
		font-size: 14px;
	}
	.policy-menu li a {
		font-size: 14px;
	}
	.footer-five .footer-contact-info {
		font-size: 14px;
	}
	.footer-five .policy-menu li a {
		font-size: 14px;
	}
	.looking-box-four .looking-inner-box .looking-info-four p {
		font-size: 14px;
	}
	.doctor-profile-widget .provider-info {
		top: calc(100% - 82px);
	}
	.doctor-profile-widget:hover .provider-info {
		top: calc(100% - 247px);
	}
	.blog-details-btn {
		margin-top: 20px;
	}
	.feature-clinic-overlay p {
		font-size: 15px;
	}
	.clinic-grid-four .clinic-content h4 {
		font-size: 18px;
	}
	.clinic-grid-four .overlay h4 {
		font-size: 18px;
	}
	.clinic-grid-four .clinic-icon-inner {
		width: 75px;
		height: 75px;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		font-size: 14px;
	}
	.blog-grid-five .blog-item-info {
		padding: 15px 0px 0px;
	}
	.blog-grid-five .blog-news-date a {
		margin-left: -15px;
	}
	.appoint {
		font-size: 20px;
	}
	.view-icons a {
		font-size: 16px;
		width: 35px;
		height: 35px;
	}
	.total-dr {
		font-size: 14px;
	}
	.doc-department{
		justify-content: center;
	}
	.clinic-booking a.view-pro-btn{
		margin-bottom: 5px;
	}
	.clinic-booking a {
		font-size: 12px;
	}
	.on-board-wizard{
		padding-top: 20px;
	}
	.pharm-grid-blog .btn {
		font-size: 15px;
	}
	.que-wrap {
		padding: 30px;
		background-size: 110px;
	}
	.que-wrap h2 {
		font-size: 22px;
	}
	.que-wrap p {
		margin-bottom: 20px;
	}
	.que-wrap ul li a {
		font-size: 14px;
		padding: 10px;
		margin-top: 10px;
	}
	.que-wrap ul li a i {
		font-size: 18px;
	}
	.pharm-slide-home img {
		display: none;
	}
	.pharm-slide-home .slide-image {
		text-align: center;
	}
	.pharm-slider .slick-dots {
		bottom: 25px;
	}
	.slide-nav-10, .slide-nav-9 {
		position: relative;
		top: 20px;
	}
	.section-help {
	    padding: 40px 0 20px;
	}
	.offer-widget {
		padding: 30px;
	}
	.offer-widget p {
		font-size: 18px;
	}
	.offer-widget h3 {
		font-size: 26px;
	}
	.offer-widget.bg-yellow .offer-img {
		right: 0px;
		text-align: right;
	}
	.offer-widget .offer-img img {
		width: 50%;
	}
	.deal-pro-detail .book-btn {
		padding: 8px 15px;
		font-size: 14px;
	}
	.pro-list .prod-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.pro-list .deal-pro-img {
		width: 100%;
	}
	.prod-widget .deal-pro-img img {
		max-width: 280px;
	}
	.pro-pricelist {
		justify-content: center;
	}
	.pro-list .deal-pro-detail h4 {
		font-size: 18px;
	}
	.breadcrumb-bar-six {
		padding: 30px 0;
	}
	.breadcrumb-bar-six .breadcrumb-title {
		font-size: 20px;
	}
	.pharm-slide-home {
		padding-bottom: 20px;
	}
	.pharm-slide-home .slide-image .book-btn1 {
		padding: 8px 15px;
	    font-size: 14px;
	    min-width: 150px;
	    margin-bottom: 20px;
	}
	.pharm-slider .slick-dots li.slick-active {
	    width: 40px;
	}
	.popular-category {
	    padding-bottom: 30px;
	}
	.offer-widget a {
		padding: 8px 15px;
	}
	.pharm-grid-blog .btn {
	    font-size: 14px;
	    padding: 8px 15px;
	    min-width: 130px;
	}
	.pharm-grid-blog .grid-blog-image {
		margin-bottom: 20px;
	}
	.pharm-grid-blog .blog-content {
	    margin-bottom: 20px;
	}
	.pharm-grid-blog .blog-content p {
	    margin-bottom: 20px;
	}
	.sort-byone {
		display: none;
	}
	.pagination {
		margin-bottom: 0;
	}
	.product-pagination .pagination li a {
	    width: 36px;
	    height: 36px;
	    font-size: 16px;
	}
	.filter-new {
	    padding: 15px;
	    margin-bottom: 20px;
	}
	.filter-info {
		display: none;
	}
	.pharmacy-content {
		padding: 40px 0 0;
	}
	.pharmacy-cart-content {
		padding: 40px 0 20px; 
	}
	.product-description-info .deal-pro-detail h4 {
		margin-bottom: 5px;
	}
	.product-description-info .brand-pro {
	    margin-bottom: 10px;
	}
	.product-description-info .pro-list .deal-pro-detail p {
	    margin-bottom: 0;
	}
	.product-description-count {
	    margin-bottom: 20px;
	}
	.product-description-pricelist {
	    margin-bottom: 20px;
	}
	.product-description-pricelist h2 {
	    font-size: 24px;
	}
	.product-description-pricelist h2 span {
	    font-size: 20px;
	}
	.product-det-buy {
		margin-right: 0;
	}
	.product-description-share ul li {
		font-size: 14px;
	}
	.product-description-share .in-share {
		font-size: 14px;
	}
	.product-description-share ul li a {
	    font-size: 16px;
	}
	.product-det-buy .product-quantity {
	    height: 40px;
	}
	.product-quantity .quntity-input {
	    font-size: 18px;
	}
	.product-quantity .quantity-btn {
	    font-size: 24px;
	}
	.product-description-tabs {
	    padding-bottom: 20px;
	}
	.product-description-tabs .nav-tabs {
		flex-direction: column;
	}
	.product-description-tabs .nav-tabs .nav-link {
		font-size: 14px;
    	padding: 10px 15px;
	}
	.product-description-tabs .nav-tabs .description-link {
		border-radius: 8px 8px 0px 0px;
	}
	.product-description-tabs .nav-tabs .reviews-link {
	    border-radius: 0px 0px 8px 8px;
	}
	.product-description-content p {
		font-size: 14px;
	}
	.related-products-section {
	    padding: 40px 0 0;
	}
	.deal-pro-img .zoom-icon {
	    bottom: 15px;
	    right: 15px;
	}
	.deal-pro-img .zoom-icon a {
		min-width: 44px;
    	height: 44px;
    	font-size: 16px;
	}
	.prod-widget .deal-pro-tags {
	    top: 15px;
	    left: 15px;
	    right: 15px;
	}
	.cart-table {
		margin-bottom: 20px;
	}
	.cart-table .table td, .cart-table .table th {
	    font-size: 14px;
	}
	.custom-increment .product-quantity .quntity-input {
		font-size: 16px;
	}
	.cart-action-btn .coupon-btn {
	    font-size: 14px;
	    min-width: 160px;
	    padding: 10px 15px;
	}
	.cart-action-link {
		min-width: 140px;
	}
	.cart-action-link a {
	    font-size: 14px;
	}
	.cart-action-btn .update-cart-btn {
	    font-size: 14px;
	    padding: 10px 15px;
	}
	.cart-action-input .form-control {
		font-size: 14px;
		height: 43px;
	}
	.cart-action-input .form-control::placeholder {
		font-size: 14px;
		min-height: 43px;
    	height: 43px;
    	min-width: 200px;
	}
	.booking-total-card {
		margin-bottom: 20px;
	}
	.booking-total-card .card-header {
		padding: 15px;
	}
	.booking-total-card .card-body {
		padding: 15px;
	}
	.booking-subtotal ul {
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
	.booking-subtotal ul:last-child {
		border: 0;
		padding-bottom: 0px;
	}
	.booking-subtotal ul li {
		font-size: 14px;
	}
	.booking-subtotal ul li .total-amount {
		font-size: 18px;
	}
	.subtotal-booking .btn {
	    font-size: 14px;
	    padding: 10px 15px;
	}
	.returning-box {
		padding: 15px;
		margin-bottom: 20px;
	}
	.returning-box p {
		font-size: 14px;
	}
	.billing-card .card-header {
		padding: 0;
	}
	.billing-card .card-header .card-title {
	    font-size: 18px;
	}
	.billing-info-widget label {
		font-size: 14px;
	} 
	.billing-info-widget .form-control {
		font-size: 14px;
	}
	.billing-info-widget .form-control::placeholder {
		font-size: 14px;
	}
	.billing-card {
	    margin-bottom: 0;
	}
	.order-table-responsive {
    	margin-bottom: 15px;
	    padding-bottom: 0;
	}
	

	.order-info {
	    margin-bottom: 20px;
	}
	.order-info .nav {
		display: block;
	}
	.order-info .nav li {
	    padding-right: 0;
	    border-right: 0;
	    font-size: 14px;
	    margin-bottom: 15px;
	} 
	.order-card-header h4 {
	    font-size: 18px;
	}
	.order-table-card .table th {
		font-size: 16px;
	}
	.order-table-card .table td {
		font-size: 14px;
	}
	.order-table-card .table td h2 a {
	    font-size: 14px;
	}
	.order-table-card .table td h2 span {
		font-size: 14px;
	}
	.pharm-register .login-header h3 {
		font-size: 18px;
	}
	.login-btn {
		font-size: 16px;
	}
	.pharm-register .btn-google {
		padding: 12px;
	}
	.acc-already p {
	    font-size: 14px;
	}
	.price-wrapper h6 {
		font-size: 14px;
	}
	.filter-checkbox .custom_check {
		font-size: 14px;
	}
	.pharmacy-widget {
		display: block;
	}
	.pharmacy-card .card-body {
	    padding: 15px;
	}
	.pharmacy-widget .doc-info-left {
		display: block;
	}
	.pharmacy-widget .doctor-img1 {
	    margin-right: 0;
	    margin-bottom: 20px;
	}
	.pharmacy-widget .doctor-img1 img {
	    border-radius: 8px;
	    width: 100%;
	}
	.pharmacy-widget .clini-infos p {
		display: inline-block;
		font-size: 14px;
	}
	.pharmacy-widget .clini-infos p i {
		font-size: 16px;
		margin-right: 6px;
	}
	.pharmacy-booking .view-details-btn {
	    margin-right: 0;
	    width: 100%;
	}
	.pharmacy-booking .browse-products-btn {
	    margin: 0;
	    width: 100%;
	}
	.pharmacy-pro-tags {
		top: 15px;
		right: 15px;
	}
	.pharmacy-pro-tags span {
		font-size: 14px;
		min-width: 100px;
    	padding: 8px 15px;
	}
	.pharmacy-social-links {
	    padding-top: 0;
	    padding-bottom: 20px;
	}
	.pharmacy-social-links .nav {
		justify-content: center;
	}
	.pharmacy-widget .doc-name {
	    padding-top: 0px;
	}
	.pharmacy-details-info .product-description-tabs .nav-tabs .nav-link {
	    padding: 10px 15px;
	}
	.pharmacy-details-info .widget {
	    margin-bottom: 20px;
	}
	.pharmacy-details-info .widget-title {
		font-size: 18px;
		margin-bottom: 15px;
	}
	.pharmacy-details-info .about-widget p {
	    font-size: 14px;
	}
	.pharmacy-business-hours {
		padding-top: 20px;
	}
	.pharmacy-business-hours h4 {
	    font-size: 18px;
	    margin-bottom: 10px;
	}
	.pharmacy-business-hours p {
	    font-size: 14px;
	    display: block;
	}
	.pharmacy-business-hours p i {
	    font-size: 14px;
	    margin-right: 2px;
	}
	.pharmacy-review-box {
	    padding: 15px;
	}
	.review-based {
	    margin-bottom: 15px;
	}
	.review-based h2 {
	    font-size: 40px;
	}
	.review-based h2 span {
		font-size: 26px;
	}
	.review-based .rating {
	    margin-bottom: 10px;
	}
	.review-based .rating i.filled {
	    font-size: 14px;
	}
	.review-based p {
	    font-size: 14px;
	}
	.review-ratings {
		display: block;
		margin-bottom: 15px;
	}
	.review-ratings .rating {
	    width: 100%;
	    margin-bottom: 10px;
	}
	.review-ratings .rating i {
    	font-size: 14px;
	}
	.review-progress {
	    padding-right: 0;
	    margin-bottom: 10px;
	    width: 100%;
	}
	.review-progress-percent p {
	    font-size: 14px;
	}
	.pharmacy-review-box {
		margin-bottom: 20px;
	}
	.pharmacy-comments-list .comment {
	    padding: 15px;
	    margin-bottom: 20px;
	}
	.pharmacy-comments-inner {
		display: block;
		padding-bottom: 15px;
   		margin-bottom: 15px;
	}
	.pharmacy-comments-profile {
		margin-bottom: 15px;
	}
	.pharmacy-comments-img {
		margin-right: 15px;
	}
	.pharmacy-comments-profile-text h4 {
		font-size: 18px;
	}
	.pharmacy-comments-profile-text p {
		font-size: 14px;
	}
	.pharmacy-comment-content {
	    margin-bottom: 15px;
	}
	.pharmacy-comments-reply {
		flex-direction: column;
	}
	.pharmacy-comments-reply .reply-btn {
		margin-bottom: 10px;
	}
	.pharmacy-comments-reply .recomment-btn .btn {
		font-size: 14px;
	}
	.pharmacy-comments-list .sub-comment {
		padding-bottom: 1px;
	}
	.add-comment {
	    margin-left: 0;
	} 
	.comment-inner {
	    margin-bottom: 20px;
	}
	.feedback-btn .btn {
		min-width: 100%;
		padding: 8px 15px;
		font-size: 14px;
		margin-bottom: 15px;
	}
	.pharmacy-details-info .widget {
		padding-bottom: 0;
	}
	.pharmacy-write-header {
	    margin-bottom: 15px;
	}
	.pharmacy-write-header h4 {
	    font-size: 18px;
	} 
	.pharmacy-write-header p {
	    font-size: 14px;
	} 
	
	.write-review-form .custom-checkbox {
		margin-bottom: 20px;
	}
	
	.write-review-form .submit-btn-info .btn {
		padding: 8px 15px;
		font-size: 14px;
	}
	.footer .footer-top {
		padding-top: 20px;
	}
}

@media only screen and (max-width: 575.98px) {
	body {
		font-size: 0.8125rem;
	}
	h1, .h1 {
		font-size: 1.75rem;
	}
	h2, .h2 {
		font-size: 1.5rem;
	}
	h3, .h3 {
		font-size: 1.25rem;
	}
	h4, .h4 {
		font-size: 1rem;
	}
	h5, .h5 {
		font-size: 0.875rem;
	}
	h6, .h6 {
		font-size: 0.75rem;
	}
	.card {
		margin-bottom: 0.9375rem;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.header-nav {
		padding-left: 15px;
		padding-right: 15px;
	}
	.header-navbar-rht {
		display: none;
	}
	.main-nav li.login-link {
		display: block;
	}
	.navbar-header {
		width: 100%;
	}
	#mobile_btn {
		left: 0;
		margin-right: 0;
		padding: 0 15px;
		position: absolute;
		z-index: 99;
	}
	.navbar-brand.logo {
		width: 100%;
		text-align: center;
		margin-right: 0;
	}
	.navbar-brand.logo img {
		height: 40px;
	}
	.search-box form {
		display: block;
	}
	.search-box .search-location {
		width: 100%;
		-ms-flex: none;
		flex: none;
	}
	.search-box .search-info {
		width: 100%;
		-ms-flex: none;
		flex: none;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 28px;
	}
	.slick-next {
		right: 0px;
	}
	.section-header h2 {
		font-size: 1.5rem;
	}
	.section-header .sub-title {
		font-size: 0.875rem;
	}
	.speicality-item p {
		font-size: 0.875rem;
	}
	.section-header p {
		font-size: 0.9375rem;
	}
	.footer-title {
		font-size: 1.125rem;
	}
	.booking-schedule.schedule-widget {
		overflow-x: auto;
	}
	.booking-schedule.schedule-widget > div {
		width: 730px;
	}
	.booking-schedule .day-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .day-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .time-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot li .timing span {
		display: inline-block;
	}
	.booking-schedule .day-slot li.right-arrow {
		right: -20px;
	}
	.booking-doc-info .booking-doc-img {
		width: 70px;
	}
	.booking-doc-info .booking-doc-img img {
		height: 70px;
		width: 70px;
	}
	.voice-call-avatar .call-avatar {
		height: 80px;
		width: 80px;
	}
	.call-duration {
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
		position: inherit;
	}
	.end-call {
		margin-top: 10px;
		position: inherit;
	}
	.user-tabs .med-records {
		min-width: 110px;
	}
	.pagination-lg .page-link {
		font-size: 1rem;
		padding: 0.5rem 0.625rem;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.edit-link {
		font-size: 14px;
		margin-top: 2px;
	}
	.invoice-content {
		padding: 1.25rem;
	}
	.change-avatar .profile-img img {
		height: 80px;
		width: 80px;
	}
	.submit-btn-bottom {
		margin-bottom: 0.9375rem;
	}
	.service-list ul li {
		width: 100%;
	}
	.blog {
		padding: 0.5rem;
	}
	.blog-title {
		font-size: 18px;
	}
	.blog-view .blog-title {
		font-size: 20px;
	}
	.blog-info i {
		font-size: 14px;
	}
	.post-left ul li {
		margin-right: 10px;
	}
	.post-left ul li:last-child {
		margin-right: 10px;
	}
	.about-author-img {
		height: 60px;
		width: 60px;
	}
	.author-details {
		margin-left: 80px;
	}
	.blog-comments .comments-list li img.avatar {
		border-radius: 40px;
		height: 40px;
		width: 40px;
	}
	.blog-comments .comments-list li {
		padding: 10px 0 0 50px;
	}
	.read-more {
		font-size: 14px;
	}
	.banner-left .banner-details h4 {
		margin-right: 0;
	}
	.news-section .news-bg {
	    padding: 30px 0 30px;
	}
	.news-bg .news-left > div {
	    padding-left: 15px;
	    padding-right: 15px;
	}
	.news-bg .news-left h2 {
		font-size: 1.5rem;
	}
	.news-bg .news-left p {
	    font-size: 14px;
	}
	.news-bg .news-left .form-control {
		min-height: 46px;
	}
	.news-bg .news-left .btn {
	    font-size: 15px;
	    color: #0071DC;
	    min-width: 160px;
	    height: 46px;
	}
	.header-three h2 {
		font-size: 1.5rem;
		padding-bottom: 15px;
	}
	.news-letter-section .news-left .news-info h2 {
		font-size: 1.5rem;
	}
	.home-four-doctor .home-four-header h2 {
	    font-size: 1.5rem;
	}
	.section-header-four h2 {
		font-size: 1.5rem;
	}
	.grid-blog-four .blog-info-four a {
	    font-size: 18px;
	}
	.grid-blog-four .blog-four-content {
		padding-bottom: 30px;
	}
	.grid-blog-four .blog-four-arrow a {
		width: 30px;
		height: 30px;
		font-size: 12px;
	}
	.section-search-five h2 {
		font-size: 1.5rem;
	}
	.looking-grid-five .looking-info-five {
		padding-top: 60px;
	}
	.looking-grid-five .looking-info-five a {
	    font-size: 20px;
	}
	.looking-grid-five .looking-info-btn .btn {
		min-width: 150px;
	    font-size: 15px;
	}
	.news-five-head h2 {
		font-size: 1.5rem;
	}
	.doctor-profile-widget .provider-info {
		top: calc(100% - 78px);
	}
	.doctor-profile-widget:hover .provider-info {
		top: calc(100% - 243px);
	}
	.header-trans.header-trans-two .logo {
	    width: 100%;
	}
	.blog-news-grid {
		padding: 10px;
	}
	.blog-news-grid .blog-news-title a {
		font-size: 18px;
	}
	.footer-news .footer-news-head h2 {
		font-size: 1.5rem;
		margin-bottom: 10px;
	}
	.onboarding-progress span {
		font-size: 13px;
	}
	.onboarding-title h2 {
		font-weight: 500;
		font-size: 18px;
	}
	.verify-box{
		padding: 5px;
	}
	.verify-item{
		font-size: 14px;
		margin-bottom: 2px;
	}
	.onboarding-content-box{
		padding: 20px;
	}
	.btn-profile img {
		height: 30px;
		width: 30px;
	}
	.upload-pic{
		height: 100px;
		width: 120px;
	}
	.passcode-wrap.mail-box input {
		font-size: 10px;
		height: 40px;
	}
	.onboarding-btn.Personalize-btn {
		margin-top: 15px;
		text-align: center;
	}
	.onboarding-btn a {
		padding: 10px 30px;
		font-size: 14px;
		margin-top: 15px;
	}
	.passcode-wrap.mail-box textarea {
		height: 90px;
	}
	.on-board-wizard {
		padding-left: 0px;
	}
	.onboarding-title h6{
		font-size: 14px;
	}
	.passcode-wrap.form-focus .focus-label {
		top: -9px;	
	}
	.onboarding-btn {
		margin-top: 10px;
		text-align: center;
	}
	.passcode-wrap input {
		width: 48px;
		height: 43px;
		font-size: 19px;
		font-weight: 400;
	}
	.right-panel .select2-container .select2-selection--single {
		height: 43px;
	}
	.right-panel .select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 40px;
		font-size: 14px;
	}
	.select2-results__option[aria-selected] {
		font-size: 11px;
	}
	.right-panel .select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 42px;
	}	
	.consult-option{
		flex-direction: column;
		align-items: center;
	}
	.call-option{
		margin-bottom: 20px;
		margin-right: 0px;
	}
	.call-lable.verify-lable.verify-options{
		width: 253px;
    	height: 118px;
	}
	.code-title{
		margin: 10px 0px;
	}
	.code-title h6 {
		font-size: 12px;
	}
	.onboarding-btn.step-2-btn {
		margin-top: 25px;
	}
	.onboarding-title {
		text-align: center;
	}
	.call-lable.verify-lable.verify-lable{
		height: 100px;
	}
	.weekDays-selector input[type=checkbox] + label{
		padding: 12px 22px;
		font-size: 14px;
	}
	.modal-body.id-pop-body h2{
		font-size: 18px;
	}
	.modal-body.id-pop-body p{
		font-size: 14px;
		margin-bottom:0px ;
	}
	.upload-pics input[type="file"]{
		height: 60px;
	}
	.upload-pic h6{
		font-size: 14px;
	}
	.photo-norms h5{
		margin-bottom: 0px;
	}
	.upload-sucess-note h6{
		font-size: 13px;
	}
	.payment-icon img{
		top: -210px;
	}
	.onboarding-btn.prefer-btn {
		margin-top: 10px;
	}
	.prefer-box{
		padding: 12px;
		font-size: 14px;
	}
	.onboarding-btn.Consultation-btn{
		margin-top: 15px;
	}
	.call-lable{
		font-size: 14px;
	}
	.right-panel .accordion-button {
		padding:20px;
	}
	.relative-file-upload {
		padding: 9px 10px;
	}
	.opt-resend {
		padding-top: 12px;
		padding-bottom: 30px;
	}
	.opt-resend a {
		font-size: 14px;
		left: auto;
    	left: 0px;
	}
	.modal-body.id-pop-body span {
		font-size: 18px;
		padding-bottom: 10px;
	}
	.select-gender-option {
		display: flex;
		flex-direction: column;
	}
	.option-set {
		display: unset;
		justify-content: end;
		padding-bottom: 20px;
	}
	.upload-patient-pic input[type="file"] {
		left:0px;
	}
	.pregnant-col .remember-me-col {
		font-size:15px;
	}
	.option-set {
		padding-right: 0px;
	}
	.title-block {
		align-items: start;
	}
	.title-block h2 {
		font-size: 35px;
		margin-bottom: 16px;
	}
	.home-section-fifteen {
		padding: 30px 0px;
	}
	.home-fifteen-section-title h2 {
		font-size: 35px;
	}
	.home-fifteen-looking-section {
		padding: 30px 0px;
	}
	.home-fifteen-looking-section .home-fifteen-section-title {
		margin-bottom: 20px;
	}
	.looking-box-count h2 {
		font-size: 41px;
	}
	.look-for-box-icon {
		height: 65px;
		width: 65px;
		margin-bottom: 23px;
	}
	.looking-for-box h5 {
		font-size: 21px;
	}
	.looking-for-box p {
		font-size: 15px;
	}
	.looking-for-box a {
		padding: 8px 18px;
	}
	.looking-for-box a {
		font-size: 16px;
	}
	.home-fifteen-why-us {
		padding: 30px 0px;
	}
	.home-fifteen-book-doctor {
		padding: 30px 0px;
	}
	.home-fifteen-section-title p {
		font-size: 15px;
		padding-top: 15px;
	}
	.doctor-sliders .pro-content .title {
		font-size: 20px;
	}
	.doctor-sliders .view-btn {
		padding: 14px 12px;
	}
	.doctor-slider .profile-widget {
		margin-bottom: 10px;
	}
	.home-fifteen-view-btn a {
		padding: 12px 17px;
	}
	.home-fifteen-features {
		padding: 30px 0px;
	}
	.feature-container-box {
		padding-top: 20px;
	}
	.newsletter-container {
		padding: 30px 0px;
	}
	.newsletter-content-box h3 {
		font-size: 28px;
	}
	.newsletter-content-box p {
		font-size: 16px;
	}
	.newsletter-box {
		padding: 30px 30px;
	}
	.newsletter-input-section input {
		min-width: 100px;
		min-height: auto;
		max-height: 45px;
	}
	.blog-category-btn a {
		font-size: 13px;
	}
	.water-mark-icons {
		display: none;
	}
	.home-fifteen .header-nav {
		height: 65px;
		padding: 0px;
	}
	.newsletter-input-section a {
		display: block;
		margin-left: 0px;
		margin-top: 15px;
		font-size: 17px;
		padding: 8px 60px;
	}
	.newsletter-input-section {
		align-items: center;
		display: flex;
		flex-direction: column;
	}
	.home-fifteen-blog-section {
		padding: 30px 0px;
	}
	.blog-inner-content a {
		font-size: 18px;
	}
	.blog-profile-box {
		padding-bottom: 0px;
	}
	.home-fifteen-footer {
		background: rgba(216, 237, 255, 0.25);
		padding: 30px 0px;
	}
	.home-fifteen-footer-block > p {
		font-size: 16px;
		padding: 20px 0px
	}
	.foot-list ul li a {
		font-size: 16px;
	}
	.foot-list-address > p {
		font-size: 16px;
	}
	.foot-line {
		margin: 20px 0px 20px 0px;
	}
	.foot-copy-rights {
		flex-direction: column;
	}
	.foot-terms-policy a {
		font-size: 16px;
	}
	.category-tab ul li a {
		font-size: 14px;
	}
	.category-tab ul li, .category-tab ul li a {
		display: block;
		width: 100%;
		margin-right: 0;
	}
	.client-slider {
		padding: 0 10px;
	}
	.pharm-testimonials img {
		margin-right: 10px;
	}
	.pro-pricelist {
		flex-direction: column;
	}
	.pro-pricelist ul li, .pro-pricelist h2, .pro-pricelist .book-btn {
		margin: 15px 0 0;
	}
	.category-tab ul li:last-child {
		margin-bottom: 0;
	}
	.pro-list .deal-pro-detail p {
		margin-bottom: 0;
	}
	.booking-total-card {
		margin-bottom: 20px;
	}
	.billing-card {
	    margin-bottom: 0;
	}
	.billing-card .card-body {
		padding: 15px 0 0;
	}
	.payment-success-card {
		margin-bottom: 20px;
	}
	.price-wrapper h6 {
		font-size: 14px;
	}
	.pharmacy-card {
		margin-bottom: 20px;
	}
}

@media only screen and (max-width:479px) {

	.latest-blog .blog-wrap .blog-wrap-body h3 {
		font-size: 16px;
	}
	.latest-blog .blog-wrap .blog-wrap-body p {
		font-size: 12px;
	}
	.latest-blog .blog-footer .read-txt, .latest-blog .blog-footer .cal-txt {
		font-size: 12px;
	}
	.aval-features .feature .info-box h2 {
		font-size: 20px;
	}		
	.home-search-section .banner-header h2 {
		font-size: 28px;
	}
	.blog-wrapper .wrap-content:before {
		border-left: 125px solid transparent;
		border-right: 125px solid transparent;
	}
	
}

